// actions & mutations
export const LOAD_ORGS = "loadOrganizations";
export const LOAD_ORGS_COMPLETED = "loadOrganizationsCompleted";
export const SAVE_ORG = "saveOrganization";
export const DELETE_ORG = "deleteOrganization";
export const SET_SELECTED_ORG = "setSelectedOrganization"
export const CLEAR_SELECTED_ORG = "clearSelectedOrganization"
export const SET_ORG_ERROR = "setOrgError"

// getters
export const GET_ORGS = "getOrganizations";
export const GET_ORGS_PAGINATION = "getOrganizationsPagination";
export const GET_ORG_BY_ID = "getOrganizationById";
export const GET_ORG_NAMES = "getOrganizationNames";
export const GET_SELECTED_ORG = "getSelectedOrganization";