const AppLayout = () => import("@/common/layouts/AppLayout");
const Sitreps = () => import("@/features/sitrep/views/Sitreps");
const SitrepForm = () => import("@/features/sitrep/views/SitrepForm");

const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "sitreps",
        name: "sitreps",
        component: Sitreps,
        meta: {
          breadcrumbs: [
            { name: "srs.sitreps", disabled: true }
          ],
          resource: "srs:sitreps"
        },
      },
      {
        path: "sitrep/:sitrepId",
        name: "sitrep",
        component: SitrepForm,
        meta: {
          breadcrumbs: [
            { name: "srs.sitreps" }, { name: "general.new", disabled: true, dynamic: true }
          ],
          resource: "srs:sitreps", back: { name: "sitreps" }
        },
      },
    ]
  },
];

function getRoutes() {
  return routes;
}

export { getRoutes };