import Vue from "vue";
import Router from "vue-router";

// Layouts
const AppLayout = () => import("@/common/layouts/AppLayout");
const DefaultLayout = () => import("@/common/layouts/DefaultLayout");

// Views
const Welcome = () => import("@/views/Welcome");
const Dashboards = () => import("@/views/dashboard/Dashboards");
const SrsDashboard = () => import("@/views/dashboard/SrsDashboard");
const Orgs = () => import("@/views/orgs/Orgs");
const OrgSelector = () => import("@/views/orgs/OrgSelector");
const OrgEditor = () => import("@/views/orgs/OrgEditor");
const Reports = () => import("@/views/reports/Reports");

// Views - Errors
const Page404 = () => import("@/views/errors/Page404");
const Page500 = () => import("@/views/errors/Page500");

// Views - Authentication
const Login = () => import("@/views/auth/Login");
const Register = () => import("@/views/auth/Register");
const ForgotPassword = () => import("@/views/auth/ForgotPassword");
const PasswordReset = () => import("@/views/auth/PasswordReset");
const ResetPassword = () => import("@/views/auth/ResetPassword");
const EmailConfirmation = () => import("@/views/auth/EmailConfirmation");

// Views - Authorized
const Users = () => import("@/views/users/Users");
const UserEditor = () => import("@/views/users/UserEditor");
const UserProfile = () => import("@/views/users/UserProfile");

// View Access Control
const Roles = () => import("@/views/ac/Roles");
const RoleEditor = () => import("@/views/ac/RoleEditor");
const Aor = () => import("@/views/ac/Aor");

// Settings
const Types = () => import("@/views/settings/Types");
const TypeEditor = () => import("@/views/settings/TypeEditor");
const BulkImport = () => import("@/views/settings/BulkImport");
const Options = () => import("@/views/settings/Options");

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboards",
      name: "home",
      component: AppLayout,
      children: [
        {
          path: "dashboards",
          name: "dashboards",
          component: Dashboards,
          meta: {
            breadcrumb: [
              { name: "route.dashboards" },
            ],
            // ac resource name
            resource: "dashboards",
            // roles: [ROLE_USER]
          },
        },
        {
          path: "dashboards/srs",
          name: "srs-dashboard",
          component: SrsDashboard,
          meta: {
            breadcrumb: [
              { name: "route.dashboards", link: "/#/dashboards" },
              { name: "dashboard.srsDashboard" },
              { name: "general.all", dynamic: true, skipTranslate: false },
            ],
            resource: "srs:incidents",
          },
        },
        // {
        //   path: "projects",
        //   name: "projects",
        //   component: OrgSelector,
        //   props: { route: "org.projects" },
        //   meta: {
        //     breadcrumb: [{ name: "route.projects" }],
        //     // ac resource name
        //     resource: "projects",
        //     // roles: [ROLE_USER]
        //   },
        // },
        {
          path: "welcome",
          name: "welcome",
          component: Welcome,
          meta: {
            authorized: true,
            breadcrumb: [
              { name: "route.welcome", disabled: true },
            ],
          },
        },
        {
          path: "reports",
          name: "reports",
          component: Reports,
          meta: {
            breadcrumb: [{ name: "route.reports" }],
            // ac resource name
            resource: "reports",
            // roles: [ADMIN, SUPERADMIN]
          },
        },
        {
          path: "orgs",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "orgs",
              component: Orgs,
              meta: {
                breadcrumb: [{ name: "route.orgs" }],
                // ac resource name
                resource: "organizations",
                // roles: [ROLE_USER]
              },
            },
            {
              path: ":id",
              name: "org",
              component: OrgEditor,
              meta: {
                breadcrumb: [
                  { name: "route.orgs", link: "/orgs" },
                  { name: "route.editor", disabled: true },
                  {
                    name: "general.new",
                    dynamic: true,
                    disabled: true,
                    skipTranslate: false,
                  },
                ],
                // ac resource name
                resource: "organizations",
                // roles: [ROLE_USER]
              },
            },
          ],
        },
        {
          path: "/ac",
          redirect: "/ac/users",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "users",
              name: "users",
              component: OrgSelector,
              props: { route: "org.users" },
              meta: {
                breadcrumb: [{ name: "route.findByOrg" }],
                // ac resource name
                resource: "users",
                // roles: [ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "org/:org/users",
              name: "org.users",
              component: Users,
              meta: {
                breadcrumb: [
                  { name: "route.orgs", link: "/#/ac/users" },
                  {
                    name: "",
                    dynamic: true,
                    skipTranslate: true,
                  },
                  { name: "route.users", disabled: true },
                ],
                // ac resource name
                resource: "users",
                // roles: [ADMIN, SUPERADMIN]
              },
            },
            {
              path: "org/:org/user/:id",
              name: "user",
              component: UserEditor,
              meta: {
                breadcrumb: [
                  { name: "route.users", link: "/#/ac/users" },
                  { name: "route.editor", disabled: true },
                  {
                    name: "general.new",
                    dynamic: true,
                    disabled: true,
                    skipTranslate: false,
                  },
                ],
                // ac resource name
                resource: "users",
                // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "org/:org/user/:id/roles",
              name: "user.roles",
              component: UserEditor,
              meta: {
                breadcrumb: [
                  { name: "route.users", link: "/#/ac/users" },
                  { name: "route.editor", disabled: true },
                  { name: "route.roles", disabled: true },
                ],
                // ac resource name
                resource: "users",
                // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "org/:org/user/:id/hubs",
              name: "user.hubs",
              component: UserEditor,
              meta: {
                breadcrumb: [
                  { name: "route.users", link: "/#/ac/users" },
                  { name: "route.editor", disabled: true },
                  { name: "route.hubs", disabled: true },
                ],
                // ac resource name
                resource: "users",
                // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "roles",
              name: "roles",
              component: Roles,
              meta: {
                breadcrumb: [{ name: "route.roles" }],
                // ac resource name
                resource: "roles",
                // roles: [ROLE_SUPERADMIN]
              },
            },
            {
              path: "role/:id",
              name: "role",
              component: RoleEditor,
              meta: {
                breadcrumb: [
                  { name: "route.roles", link: "/#/ac/roles" },
                  { name: "route.editor", disabled: true },
                  {
                    name: "",
                    dynamic: true,
                    skipTranslate: true,
                  },
                ],
                // ac resource name
                resource: "roles",
                // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "aor",
              name: "aor",
              component: Aor,
              meta: {
                breadcrumb: [{ name: "route.aor" }],
                // ac resource name
                resource: "aor",
                // roles: [ROLE_SUPERADMIN]
              },
            },
          ],
        },
        {
          name: "profile",
          path: "profile",
          component: UserProfile,
          meta: {
            breadcrumb: [
              { name: "route.profile", disabled: true },
              {
                name: "",
                dynamic: true,
                skipTranslate: true,
              },
            ],
            // ac resource name
            resource: "profile",
            // roles: [ROLE_USER, ROLE_ADMIN, ROLE_SUPERADMIN]
          },
        },
        {
          path: "/settings",
          redirect: "/settings/types",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "types",
              name: "types",
              component: Types,
              meta: {
                breadcrumb: [
                  { name: "route.settings", disabled: true },
                  { name: "route.dataTypes" },
                ],
                // ac resource name
                resource: "datatypes",
                // roles: [ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "type/:route",
              name: "type",
              component: TypeEditor,
              meta: {
                breadcrumb: [
                  { name: "route.settings", disabled: true },
                  { name: "route.dataTypes", link: "/#/settings/types" },
                  {
                    dynamic: true,
                    skipTranslate: false,
                  },
                ],
                // ac resource name
                resource: "datatypes",
                // roles: [ROLE_ADMIN, ROLE_SUPERADMIN]
              },
            },
            {
              path: "import",
              name: "import",
              component: BulkImport,
              meta: {
                breadcrumb: [{ name: "route.bulkImport" }],
                // ac resource name
                resource: "bulkimport",
                // roles: [ADMIN, SUPERADMIN]
              },
            },
            {
              path: "options",
              name: "options",
              component: Options,
              meta: {
                breadcrumb: [
                  { name: "route.settings", disabled: true },
                  { name: "options.title" }
                ],
                // ac resource name
                resource: "notifications",
                // roles: [ADMIN, SUPERADMIN]
              },
            },
          ],
        },
      ],
    },
    {
      path: "/auth",
      redirect: "/auth/login",
      component: DefaultLayout,
      children: [
        {
          path: "login",
          name: "login",
          component: Login,
        },
        {
          path: "register",
          name: "register",
          component: Register,
        },
        {
          path: "email/:code?",
          name: "email",
          component: EmailConfirmation,
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          component: ForgotPassword,
        },
        {
          path: "password-reset",
          name: "password-reset",
          component: PasswordReset,
        },
        {
          path: "reset-password/:code?",
          name: "reset-password",
          component: ResetPassword,
        },
      ],
    },
    {
      path: "/error",
      redirect: "/error/page-not-found",
      name: "error",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "page-not-found",
          name: "e404",
          component: Page404,
        },
        {
          path: "500",
          name: "e500",
          component: Page500,
        },
      ],
    },
  ];
}

function daynamicRoutes() {
  const routes = configRoutes();

  // Define the base directory to search for route.js files
  const routeFiles = require.context("@/features", true, /\/scripts\/index\.js$/)

  // Iterate over all matching files and call the getRoutes function
  const fileKeys = routeFiles.keys();
  fileKeys.forEach((key) => {
    const module = routeFiles(key);
    if (module.getRoutes) {
      const featureRoutes = module.getRoutes();
      featureRoutes.forEach((fr) => {
        const root = routes.find((r) => r.path === fr.path);
        if(root) {
          root.children.push(...fr.children);
        } else {
          routes.push(fr);
        }
      })
    }
  })

  return routes;
}


export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: daynamicRoutes(),
});

Vue.use(Router);
