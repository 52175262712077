import * as actionTypes from "../actions/type.ui";


const state = {
  activeDialog: null,
  activeNotification: { show: false, timeout: 4000, alert: false },
  breadcrumbs: [],
  notifications: [],
  language: localStorage.getItem("language") ? localStorage.getItem("language") : "en",
  loading: { state: false, message: "" },
  isCommentsEnabled: false,
  menuBadges: { alerts: [] },
};

const getters = {
  [actionTypes.GET_BREADCRUMBS](state) {
    return state.breadcrumbs;
  },
  [actionTypes.GET_LANGUAGE](state) {
    return state.language;
  },
  [actionTypes.GET_NOTIFICATIONS](state) {
    return state.notifications;
  },
  [actionTypes.GET_LOADING_STATE](state) {
    return state.loading.state;
  },
  [actionTypes.GET_LOADING_MESSAGE](state) {
    return state.loading.message;
  },
  [actionTypes.GET_ACTIVE_DIALOG](state) {
    return state.activeDialog;
  },
  [actionTypes.GET_ACTIVE_NOTIFICATION](state) {
    return state.activeNotification;
  },
  [actionTypes.IS_COMMENTS_ENABLED](state) {
    return state.isCommentsEnabled;
  },
  [actionTypes.GET_MENU_BADGES]: (state) => (resource) => {
    return state.menuBadges[resource];
  },
  [actionTypes.GET_MENU_BADGES_COUNTER]: (state) => (resource) => {
    return state.menuBadges[resource] ? state.menuBadges[resource].length : 0;
  }
};

const actions = {
  [actionTypes.SET_BREADCRUMBS]({ commit }, params) {
    commit(actionTypes.SET_BREADCRUMBS, params);
  },
  [actionTypes.SET_LOADING_STATE]({ commit }, message) {
    commit(actionTypes.SET_LOADING_STATE, message);
  },
  [actionTypes.CLEAR_LOADING_STATE]({ commit }) {
    commit(actionTypes.CLEAR_LOADING_STATE);
  },
  [actionTypes.SET_LANGUAGE]({ commit }, lang) {
    commit(actionTypes.SET_LANGUAGE, lang);
  },
  [actionTypes.ADD_NOTIFICATION]({ commit }, notification) {
    commit(actionTypes.ADD_NOTIFICATION, notification);
  },
  [actionTypes.DISMISS_NOTIFICATION]({ commit }) {
    commit(actionTypes.DISMISS_NOTIFICATION);
  },
  [actionTypes.SHOW_DIALOG]({ commit }, dialog) {
    commit(actionTypes.SHOW_DIALOG, dialog);
  },
  [actionTypes.SHOW_ALERT]({ commit }, alert) {
    const dialog = {
      actions: [
        {
          text: "general.okay",
          click: () => {
            commit(actionTypes.CLOSE_DIALOG);
          },
          color: "primary",
        },
      ],
      color: "yellow",
      ...alert
    };

    commit(actionTypes.SHOW_DIALOG, dialog);
  },
  [actionTypes.REFRESH_DIALOG]({ commit }) {
    commit(actionTypes.REFRESH_DIALOG);
  },
  [actionTypes.CLOSE_DIALOG]({ commit }) {
    commit(actionTypes.CLOSE_DIALOG);
  },
  [actionTypes.SET_COMMENTS_ENABLED]({ commit }, value) {
    commit(actionTypes.SET_COMMENTS_ENABLED, value);
  },
  [actionTypes.ADD_TO_MENU_BADGE]({ commit }, params) {
    const { resource, id } = params;
    commit(actionTypes.ADD_TO_MENU_BADGE, { resource, id });
  },
};

const mutations = {
  [actionTypes.SET_BREADCRUMBS](state, payload) {
    if (payload && payload.length > 0) {
      state.breadcrumbs = payload.filter((f) => !f.skip);
    } else {
      state.breadcrumbs = [];
    }
  },
  [actionTypes.SET_LOADING_STATE](state, payload) {
    state.loading = { state: true, message: payload };
  },
  [actionTypes.CLEAR_LOADING_STATE](state) {
    state.loading = { state: false, message: "" };
  },
  [actionTypes.SET_LANGUAGE](state, payload) {
    localStorage.setItem("language", payload);
    state.language = payload;
  },
  [actionTypes.ADD_NOTIFICATION](state, payload) {
    state.notifications.push(payload);
    state.activeNotification = { timeout: 4000, ...payload, show: true };
  },
  [actionTypes.DISMISS_NOTIFICATION](state) {
    state.activeNotification = { show: false, timeout: 4000, alert: false };
  },
  [actionTypes.SHOW_DIALOG](state, payload) {
    payload.show = true;
    state.activeDialog = payload;
  },
  [actionTypes.REFRESH_DIALOG](state) {
    state.activeDialog = { ...state.activeDialog };
  },
  [actionTypes.CLOSE_DIALOG](state) {
    state.activeDialog = null;
  },
  [actionTypes.SET_COMMENTS_ENABLED](state, payload) {
    state.isCommentsEnabled = payload;
  },
  [actionTypes.ADD_TO_MENU_BADGE](state, payload) {
    state.menuBadges[payload.resource] = [...state.menuBadges[payload.resource], payload.id];
  },
  [actionTypes.CLEAR_MENU_BADGE](state, payload) {
    state.menuBadges[payload] = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
