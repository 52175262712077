import Vue from 'vue';
import { Tab, Tabs } from 'vant';
import 'vant/lib/index.css';

Vue.use(Tab);
Vue.use(Tabs);

export default {
  components: {
    'van-tabs': Tabs,
    'van-tab': Tab,
  }
}