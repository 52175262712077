const globalLocale = {
  uk: {
    general: {
      id: "Id",
      name: "Назва ситрепу",
    },
    srs: {
      sitrep: "Ситреп",
      sitreps: "Ситрепи"
    },
    sitrep: {
      sitrepForm: "Ситреп",
      sitrepDate: "Дата ситрепу",
      tags: "Теги",
      file: "Файл",
      reuploadFile: "Перезавантажити файл",
      confirmDelte: "Ви впевнені, що хочете видалити цей файл?",
    },
  },
  en: {
    general: {
      id: "Id",
      name: "Sitrep Name",
    },
    srs: {
      sitrep: "Sitrep",
      sitreps: "Sitreps"
    },
    sitrep: {
      sitrepForm: "Sitrep",
      sitrepDate: "Sitrep Date",
      tags: "Tags",
      file: "File",
      reuploadFile: "Reupload File",
      confirmDelte: "Are you sure you want to delete this file?",
    },
  }
};

function getLocale() {
  return {
    global: globalLocale
  };
}

export { getLocale };