import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

const API_URL = process.env.ROOT_API;

export default {
  install(Vue) {
    let connected = false;
    let connectedBy = null;

    const pushCommentFunc = (commentId) => {
      console.log("ws: sending...");
      if (this.stompClient && this.stompClient.connected) {
        // this.stompClient.send("/app/send", JSON.stringify(message), {});
        this.stompClient.send("/app/push.comment", commentId, {});
      }
    };

    const connectFunc = (account, onMessageReceived) => {
      const { username, organizationId } = account;
      if(connectedBy === username) {
        return;
      }

      this.socket = new SockJS(`${API_URL}/ws`);
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect(
        {},
        // (frame) => {
        () => {
          connected = true;
          this.stompClient.send(
            "/app/register",
            JSON.stringify({ username, organizationId })
          );

          connectedBy = username;

          if (account.isAdmin) {
            this.stompClient.subscribe("/message/admin", onMessageReceived);
          } else {
            this.stompClient.subscribe(
              `/message/org/${organizationId}`,
              onMessageReceived
            );
          }
          this.stompClient.subscribe(
            `/message/user/${username}`,
            onMessageReceived
          );
      },
        (error) => {
          console.log(error);
          connected = false;
          connectedBy = null;
        }
      );
    };

    const disconnectFunc = () => {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      connected = false;
      connectedBy = null;
    };

    const toggleConnectionFunc = () => {
      connected ? disconnectFunc() : connectFunc();
    };

    Vue.wsConnect = connectFunc;
    Vue.wsDisconnect = disconnectFunc;
    Vue.wsPushComment = pushCommentFunc;
    Vue.wsToggleConnection = toggleConnectionFunc;

    Vue.mixin({
      methods: {
        $wsConnect(account, onMessageReceived) {
          connectFunc(account, onMessageReceived);
        },
        $wsDisconnect() {
          disconnectFunc();
        },
        $wsPushComment(commentId) {
          pushCommentFunc(commentId);
        },
        $wsToggleConnection() {
          toggleConnectionFunc();
        },
      },
    });
  },
};
