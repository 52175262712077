// actions & mutations
export const LOAD_ADMIN1_AREAS = "loadAdmin1Areas";
export const LOAD_ADMIN1_AREAS_COMPLETED = "loadAdmin1AreasCompleted";
export const LOAD_ADMIN2_AREAS = "loadAdmin2Areas";
export const LOAD_ADMIN2_AREAS_COMPLETED = "loadAdmin2AreasCompleted";
export const LOAD_ADMIN3_AREAS = "loadAdmin3Areas";
export const LOAD_ADMIN3_AREAS_COMPLETED = "loadAdmin3AreasCompleted";
export const LOAD_ADMIN4_AREAS = "loadAdmin4Areas";
export const LOAD_ADMIN4_AREAS_COMPLETED = "loadAdmin4AreasCompleted";

//getters
export const GET_ADMIN1_AREAS = "getAdmin1Areas";
export const GET_ADMIN2_AREAS = "getAdmin2Areas";
export const GET_ADMIN3_AREAS = "getAdmin3Areas";
export const GET_ADMIN4_AREAS = "getAdmin4Areas";
export const GET_ADMIN1_AREAS_BY_CODES = "getAdmin1AreasByCodes";
export const GET_ADMIN2_AREAS_BY_CODES = "getAdmin2AreasByCodes";
export const GET_ADMIN3_AREAS_BY_CODES = "getAdmin3AreasByCodes";
export const GET_ADMIN4_AREAS_BY_CODES = "getAdmin4AreasByCodes";
export const GET_ADMIN2_AREAS_BY_ADMIN1_CODES = "getAdmin2AreasByAdmin1Codes";
export const GET_ADMIN3_AREAS_BY_ADMIN2_CODES = "getAdmin3AreasByAdmin2Codes";
export const GET_ADMIN4_AREAS_BY_ADMIN3_CODES = "getAdmin4AreasByAdmin3Codes";
