import axios from "axios";

export default {
  install(Vue) {
    const readFunc = (file) => {
      return new Promise((resolve, reject) => {
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", file, false);
        rawFile.onreadystatechange = function () {
          if (rawFile.readyState === 4) {
            if (rawFile.status === 200 || rawFile.status == 0) {
              resolve(rawFile.responseText);
            } else {
              reject();
            }
          }
        };
        rawFile.send(null);
      })
    };

    const uploadFunc = (url, file, name, meta) => {
      if (typeof url !== "string") {
        throw new TypeError(`Expected a string, got ${typeof url}`);
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);
      formData.append("meta", meta);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return axios.post(url, formData, config);
    };

    const uploadDtoFunc = (url, file, dto) => {
      if (typeof url !== "string") {
        throw new TypeError(`Expected a string, got ${typeof url}`);
      }

      const formData = new FormData();
      formData.append("dto", new Blob([JSON.stringify(dto)], { type: 'application/json' }));
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.ROOT_API}${url}`, formData, config).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    };

    Vue.upload = uploadFunc;
    Vue.uploadDto = uploadDtoFunc;

    Vue.mixin({
      methods: {
        $upload(url, file, name, meta) {
          return uploadFunc(url, file, name, meta);
        },
        $uploadDto(url, file, dto) {
          return uploadDtoFunc(url, file, dto);
        },
        $readFile(file) {
          return readFunc(file);
        },
      },
    });
  },
};
