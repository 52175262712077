import ApiService from "../../api/api.service";
import * as actionTypes from "../actions/type.options";
import { ADD_NOTIFICATION } from "../actions/type.ui";

const OPTIONS_REF = "options";
const ACTUATOR_REFRESH_REF = "actuator/refresh";

// initial state
const state = {
  notificationOptions: [],
  notificationOptionsAreLoading: false,
};

// getters
const getters = {
  [actionTypes.GET_NOTIFICATION_OPTIONS]: (state) => () => {
    return state.notificationOptions;
  },
};

// actions
const actions = {
  async [actionTypes.LOAD_NOTIFICATION_OPTIONS]({ commit }) {
    commit(actionTypes.LOAD_NOTIFICATION_OPTIONS);
    return new Promise((resolve, reject) => {
      ApiService.get(OPTIONS_REF)
        .then(({ data }) => {
          commit(actionTypes.LOAD_NOTIFICATION_OPTIONS_COMPLETED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.SAVE_NOTIFICATION_OPTIONS]({ commit, dispatch }, params) {
    if (!params) {
      return;
    }

    return ApiService.post(OPTIONS_REF, params)
      .then(({ data }) => {
        // calling actuator/refresh to update application-options bean on the server
        ApiService.post(ACTUATOR_REFRESH_REF);
        commit(actionTypes.SAVE_NOTIFICATION_OPTIONS, data);
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.updateCompleted",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.operationError",
            color: "grey",
          },
          { root: true }
        );
        throw new Error(error);
      });
  },
  async [actionTypes.SEND_BULK_EMAILS]({ commit, dispatch }, params) {
    if (!params) {
      return;
    }

    const url = `${OPTIONS_REF}/send.bulk`;

    return ApiService.post(url, params)
      .then(({ data }) => {
        commit(actionTypes.SEND_BULK_EMAILS, data);
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.updateCompleted",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.operationError",
            color: "grey",
          },
          { root: true }
        );
        throw new Error(error);
      });
  },
};

// mutations
const mutations = {
  [actionTypes.LOAD_NOTIFICATION_OPTIONS](state) {
    state.notificationOptionsAreLoading = true;
  },
  [actionTypes.LOAD_NOTIFICATION_OPTIONS_COMPLETED](state, payload) {
    state.notificationOptions = payload;
    state.notificationOptionsAreLoading = false;
  },
  [actionTypes.SAVE_NOTIFICATION_OPTIONS](state, payload) {
    state.notificationOptions = payload;
    state.notificationOptionsAreLoading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
