import ApiService from "@/api/api.service";
import * as actionTypes from "./feeds.types";

const FEEDS_REF = "si";

// initial state
const state = {
  feeds: [],
  isLoading: false,
  selectedFeed: null,
};

// getters
const getters = {
  [actionTypes.GET_FEEDS]: (state) => {
    return state.feeds;
  },
  [actionTypes.GET_FEEDS_TOTAL]: (state) => {
    return state.feeds.length;
  },
  [actionTypes.GET_SELECTED_FEED]: (state) => {
    return state.selectedFeed;
  },
};

// actions
const actions = {
  async [actionTypes.LOAD_FEEDS]({ commit }) {
    commit(actionTypes.LOAD_FEEDS);
    return new Promise((resolve, reject) => {
      ApiService.query(`${FEEDS_REF}/feeds`)
        .then(({ data }) => {
          commit(actionTypes.LOAD_FEEDS_COMPLETED, { data });
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        });
    });
  },
  async [actionTypes.SYNC_LOAD_FEEDS]({ commit }) {
    commit(actionTypes.LOAD_FEEDS);
    return new Promise((resolve, reject) => {
      ApiService.query(`${FEEDS_REF}/sync.feeds`)
        .then(({ data }) => {
          commit(actionTypes.LOAD_FEEDS_COMPLETED, { data });
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        });
    });
  },
  [actionTypes.SET_SELECTED_FEED]({ commit }, feed) {
    commit(actionTypes.SET_SELECTED_FEED, feed);
  },
  [actionTypes.CLEAR_SELECTED_FEED]({ commit }) {
    commit(actionTypes.CLEAR_SELECTED_FEED);
  },
};

// mutations
const mutations = {
  [actionTypes.LOAD_FEEDS](state) {
    state.isLoading = true;
  },
  [actionTypes.LOAD_FEEDS_COMPLETED](state, payload) {
    if(!payload || !payload.data) {
      return;
    }

    state.feeds = payload.data;
    state.isLoading = false;
  },
  [actionTypes.SET_SELECTED_FEED](state, payload) {
    state.selectedFeed = payload;
  },
  [actionTypes.CLEAR_SELECTED_FEED](state) {
    state.selectedFeed = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
