const links = [
  {
    title: "srs.incidents",
    resource: "srs:incidents",
    icon: "mdi-alarm-light",
    link: "/#/incidents",
    to: "incidents",
  },
  {
    title: "srs.map",
    resource: "srs:map",
    icon: "mdi-map-marker",
    link: "/#/map",
    to: "map",
  },
  {
    title: "srs.newsFeed",
    resource: "srs:feeds",
    icon: "mdi-comment-quote-outline",
    link: "/#/feeds",
    to: "feeds",
  },
];

function getMenu() {
  return links;
}

export { getMenu };