import ApiService from "@/api/api.service";
import { GET_MY_AOR_ADMIN1, GET_MY_AOR_ADMIN2 } from "../actions/type.aor";
import * as actionTypes from "../actions/type.areas";

const ADMIN1_REF = "areas/admin1";
const ADMIN2_REF = "areas/admin2";
const ADMIN3_REF = "areas/admin3";
const ADMIN4_REF = "areas/admin4";
// const ADMIN3_REF = "areas/admin3";

const state = {
  admin1Areas: [],
  admin2Areas: [],
  admin3Areas: [],
  admin4Areas: [],
  isLoadingAdmin1: false,
  isLoadingAdmin2: false,
  isLoadingAdmin3: false,
  isLoadingAdmin4: false,
};

const getters = {
  [actionTypes.GET_ADMIN1_AREAS]: (state, getters, rootState, rootGetters) => {
    const propName = rootState.ui.language === "ar" ? "nameUk" : "name";
    const items = state.admin1Areas;
    const aor = rootGetters[`aor/${GET_MY_AOR_ADMIN1}`];
    const areas =
      aor && aor.length > 0
        ? items.filter((f) => aor.includes(f.pcode))
        : items;
    return areas
      ? areas.sort((x, y) => {
          let a = x[propName].toUpperCase(),
            b = y[propName].toUpperCase();
          return a == b ? 0 : a > b ? 1 : -1;
        })
      : [];
  },
  [actionTypes.GET_ADMIN2_AREAS]: (state, getters, rootState, rootGetters) => {
    const propName = rootState.ui.language === "ar" ? "nameUk" : "name";
    const items = state.admin2Areas;
    const aor = rootGetters[`aor/${GET_MY_AOR_ADMIN2}`];
    const areas =
      aor && aor.length > 0
        ? items.filter((f) => aor.includes(f.pcode))
        : items;
    return areas
      ? areas.sort((x, y) => {
          let a = x[propName].toUpperCase(),
            b = y[propName].toUpperCase();
          return a == b ? 0 : a > b ? 1 : -1;
        })
      : [];
  },
  [actionTypes.GET_ADMIN3_AREAS]: (state) => {
    return state.admin3Areas;
  },
  [actionTypes.GET_ADMIN4_AREAS]: (state) => {
    return state.admin4Areas;
  },
  [actionTypes.GET_ADMIN1_AREAS_BY_CODES]: (state, getters) => (codes) => {
    if (!codes || codes.length === 0) {
      return [];
    }

    return getters.getAdmin1Areas.filter((f) => codes.includes(f.pcode));
  },
  [actionTypes.GET_ADMIN2_AREAS_BY_CODES]: (state, getters) => (codes) => {
    if (!codes || codes.length === 0) {
      return [];
    }

    return getters.getAdmin2Areas.filter((f) => codes.includes(f.pcode));
  },
  [actionTypes.GET_ADMIN2_AREAS_BY_ADMIN1_CODES]: (state, getters) => (codes) => {
    if (!codes || codes.length === 0) {
      return [];
    }
    
    return getters.getAdmin2Areas.filter((f) => codes.includes(f.admin1PCode));
  },
  [actionTypes.GET_ADMIN3_AREAS_BY_CODES]: (state) => (codes) => {
    if (!codes || codes.length === 0) {
      return [];
    }

    return state.admin3Areas.filter((f) => codes.includes(f.pcode));
  },
  [actionTypes.GET_ADMIN3_AREAS_BY_ADMIN2_CODES]: (state) => (codes) => {
    if (!codes || codes.length === 0) {
      return [];
    }

    return state.admin3Areas.filter((f) => codes.includes(f.admin2PCode));
  },

  [actionTypes.GET_ADMIN4_AREAS_BY_ADMIN3_CODES]: (state) => (codes) => {
    if (!codes || codes.length === 0) {
      return [];
    }

    return state.admin4Areas.filter((f) => codes.includes(f.admin3PCode));
  },
};

const actions = {
  async [actionTypes.LOAD_ADMIN1_AREAS]({ commit, state, rootState }, params) {
    return new Promise((resolve, reject) => {
      if (state.admin1Areas && state.admin1Areas.length > 0) {
        resolve(state.admin1Areas);
        return;
      }

      commit(actionTypes.LOAD_ADMIN1_AREAS);
      ApiService.query(ADMIN1_REF, params)
        .then(({ data }) => {
          const sortBy = rootState.ui.language === "ar" ? "nameUk" : "name";
          commit(actionTypes.LOAD_ADMIN1_AREAS_COMPLETED, {
            payload: data,
            sortBy,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.LOAD_ADMIN2_AREAS]({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      if (state.admin2Areas && state.admin2Areas.length > 0) {
        resolve(state.admin2Areas);
        return;
      }

      commit(actionTypes.LOAD_ADMIN2_AREAS);
      ApiService.query(ADMIN2_REF, params)
        .then(({ data }) => {
          commit(actionTypes.LOAD_ADMIN2_AREAS_COMPLETED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.LOAD_ADMIN3_AREAS]({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      if (state.admin3Areas && state.admin3Areas.length > 0) {
        resolve(state.admin3Areas);
        return;
      }

      commit(actionTypes.LOAD_ADMIN3_AREAS);
      ApiService.query(ADMIN3_REF, params)
        .then(({ data }) => {
          commit(actionTypes.LOAD_ADMIN3_AREAS_COMPLETED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.LOAD_ADMIN4_AREAS]({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      if (state.admin4Areas && state.admin4Areas.length > 0) {
        resolve(state.admin4Areas);
        return;
      }

      commit(actionTypes.LOAD_ADMIN4_AREAS);
      ApiService.query(ADMIN4_REF, params)
        .then(({ data }) => {
          commit(actionTypes.LOAD_ADMIN4_AREAS_COMPLETED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
};

const mutations = {
  [actionTypes.LOAD_ADMIN1_AREAS](state) {
    state.isLoadingAdmin1 = true;
  },
  [actionTypes.LOAD_ADMIN1_AREAS_COMPLETED](state, { payload, sortBy }) {
    state.admin1Areas = payload
      ? payload.sort((x, y) => {
          let a = x[sortBy] ? x[sortBy].toUpperCase() : -1,
            b = y[sortBy] ? y[sortBy].toUpperCase() : -1;
          return a == b && a > -1 ? 0 : a > b ? 1 : -1;
        })
      : payload;
    state.isLoadingAdmin1 = false;
  },
  [actionTypes.LOAD_ADMIN2_AREAS](state) {
    state.isLoadingAdmin2 = true;
  },
  [actionTypes.LOAD_ADMIN2_AREAS_COMPLETED](state, payload) {
    state.admin2Areas = payload;
    state.isLoadingAdmin2 = false;
  },
  [actionTypes.LOAD_ADMIN3_AREAS](state) {
    state.isLoadingAdmin3 = true;
  },
  [actionTypes.LOAD_ADMIN3_AREAS_COMPLETED](state, payload) {
    state.admin3Areas = payload;
    state.isLoadingAdmin3 = false;
  },
  [actionTypes.LOAD_ADMIN4_AREAS](state) {
    state.isLoadingAdmin4 = true;
  },
  [actionTypes.LOAD_ADMIN4_AREAS_COMPLETED](state, payload) {
    state.admin4Areas = payload;
    state.isLoadingAdmin4 = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
