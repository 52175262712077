export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const PASSWORD_RESET = "passwordReset";
export const RESET_PASSWORD = "resetPassword";
// export const CHANGE_PASSWORD = "changePassword";
export const SET_AUTH = "setAuth";
export const CHECK_AUTH = "checkAuth";
export const PURGE_AUTH = "purgeAuth";
export const SET_AUTH_ERROR = "setAuthError";
export const CONFIRM_EMAIL = "confirmEmail";

export const GET_CURRENT_USER = "getCurrentUser";
export const GET_CURRENT_USER_ID = "getCurrentUserId";
export const GET_USER_ORG_ID = "getUserOrgId";
export const Is_AUTHONITICATED = "isAuthenticated";
