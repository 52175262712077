// actions & mutations
export const LOAD_SRS = "loadSrs";
export const LOAD_ORGANIZATION_SRS = "loadOrganizationSrs";
export const LOAD_SRS_COMPLETED = "loadSrsCompleted";
export const LOAD_SRS_ACTORS = "loadSrsActors";
export const LOAD_SRS_ACTORS_COMPLETED = "loadSrsActorsCompleted";
export const LOAD_SRS_CONSTRAINTS = "loadSrsConstraints";
export const LOAD_SRS_CONSTRAINTS_COMPLETED = "loadSrsConstraintsCompleted";
export const LOAD_SRS_ACTIVITIES = "loadSrsActivities";
export const LOAD_SRS_ACTIVITIES_COMPLETED = "loadSrsActivitiesCompleted";
export const FITCH_SRS = "fitchSrs";
export const SAVE_SRS = "saveSrs";
export const DELETE_SRS = "deleteSrs";
export const DELETE_SRS_REQUEST= "deleteSrsRequest";
export const SET_SELECTED_SRS = "setSelectedSrs"
export const SET_SRS_RELOAD_MODE = "setSrsReloadMode"
export const CLEAR_SELECTED_SRS = "clearSelectedSrs"
export const CLEAR_SRS = "clearSrs"
export const FETCH_SRS_COUNT = "fetchSrsCount"


export const LOAD_DASHBOARD_SRS = "loadDashboardSrs";
export const LOAD_ORGANIZATION_DASHBOARD_SRS = "loadOrganizationDashboardSrs";
export const LOAD_DASHBOARD_SRS_COMPLETED = "loadDashboardSrsCompleted";

// export const VERIFY_SRS = "verifySrs";

// getters
export const GET_SRS_TOTAL = "getSrsTotal";
export const GET_SRS = "getSrs";
export const GET_SRS_PAGE = "getSrsPage";
export const GET_SRS_RELOAD_MODE = "getSrsReloadMode";
export const GET_ORGANIZATION_MODE = "getOrganizationMode";
export const GET_SRS_BY_ID = "getSrsById";
export const GET_SELECTED_SRS = "getSelectedSrs";

export const GET_DASHBOARD_SRS = "getDashboardSrs";
export const GET_ORGANIZATION_DASHBOARD_SRS = "getOrganizationDashboardSrs";
