export const LOAD_USERS = "loadUsers";
export const LOAD_USERS_COMPLETED = "loadUsersCompleted";
export const LOAD_USER_PROFILE = "loadUserProfile";
export const LOAD_USER_PROFILE_COMPLETED = "loadUserProfileCompleted";
export const SAVE_USER = "saveUser";
export const SAVE_USER_PROFILE = "saveUserProfile";
export const SAVE_USER_ROLES = "saveUserRoles";
export const SAVE_USER_HUBS = "saveUserHubs";
export const DELETE_USER = "deleteUser";
export const SET_SELECTED_USER = "setSelectedUser";
export const CLEAR_SELECTED_USER = "clearSelectedUser";
export const SET_USER_ERROR = "setUserError";

// getters
export const GET_USERS = "getUsers";
export const GET_USER_PROFILE = "getUserProfile";
export const GET_USER_BY_ID = "getUserById";
export const GET_USERS_BY_ORG_ID = "getUsersByOrgId";
export const GET_USER_ERROR = "getUserError";