const AppLayout = () => import("@/common/layouts/AppLayout");
const Incidents = () => import("@/features/si/views/Incidents");
const Feeds = () => import("@/features/si/views/Feeds");
const IncidentForm = () => import("@/features/si/views/IncidentForm");
const IncidentRFForm = () => import("@/features/si/views/IncidentRFForm");
const incidentsMap = () => import("@/features/si/views/IncidentsMap");

const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "incidents",
        name: "incidents",
        component: Incidents,
        meta: { breadcrumbs: [{ name: "srs.incidents", disabled: true }], resource: "srs:incidents" },
      },
      {
        path: "map",
        name: "map",
        component: incidentsMap,
        meta: { breadcrumbs: [{ name: "srs.incidents", disabled: true }], resource: "srs:map" },
      },
      {
        path: "incident/:incidentId",
        name: "incident",
        component: IncidentForm,
        meta: { breadcrumbs: [{ name: "srs.incidents" }, { name: "general.new", disabled: true, dynamic: true }], resource: "srs:incidents", back: { name: "incidents" } },
      },
      {
        path: "incident-rf/:incidentId",
        name: "incident-rf",
        component: IncidentRFForm,
        meta: { breadcrumbs: [{ name: "srs.incidents" }, { name: "general.new", disabled: true, dynamic: true }], resource: "srs:incidents", back: { name: "incidents" } },
      },
      {
        path: "feeds",
        name: "feeds",
        component: Feeds,
        meta: { breadcrumbs: [{ name: "srs.feeds", disabled: true }], resource: "srs:feeds" },
      },
    ]
  },
];

function getRoutes() {
  return routes;
}

export { getRoutes };