import ApiService from "../../api/api.service";
import * as actionTypes from "../actions/type.users";
import { ADD_NOTIFICATION } from "../actions/type.ui";

const USERS_REF = "users";
// initial state
const state = {
  users: [],
  // no pagination for users
  isLoading: false,
  selectedUser: null,
  error: null,
};

// getters
const getters = {
  [actionTypes.GET_USERS]: (state) => {
    return state.users;
  },
  [actionTypes.GET_USER_PROFILE]: (state, getters, rootState) => () => {
    return state.users.find(
      (item) => item.username === rootState.auth.user.username
    );
  },
  [actionTypes.GET_USER_BY_ID]: (state) => (id) => {
    return state.users.find((item) => item.id === id);
  },
  [actionTypes.GET_USERS_BY_ORG_ID]: (state) => (id) => {
    if(id) {
      return state.users.filter((item) => item.organizationId === id);
    }
    return state.users;
  },
  [actionTypes.GET_USER_ERROR]: (state) => {
    return state.error;
  },
};

// actions
const actions = {
  // no pagination for users
  async [actionTypes.LOAD_USERS]({ commit }, { orgId }) {
    commit(actionTypes.LOAD_USERS);
    return new Promise((resolve, reject) => {
      let url = USERS_REF;
      if (orgId > 0) {
        url = `${USERS_REF}/org/${orgId}`;
      } else if(orgId === -1) {
        url = `${USERS_REF}`;
      } else {
        reject();
      }

      ApiService.get(url)
        .then(({ data }) => {
          commit(actionTypes.LOAD_USERS_COMPLETED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.LOAD_USER_PROFILE]({ commit }) {
    commit(actionTypes.LOAD_USER_PROFILE);
    return new Promise((resolve, reject) => {
      let url = `${USERS_REF}/profile`;

      ApiService.get(url)
        .then(({ data }) => {
          commit(actionTypes.LOAD_USER_PROFILE_COMPLETED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.SAVE_USER]({ commit, dispatch }, params) {
    let { editMode, item } = params;
    if (!item) {
      return;
    }

    if (editMode) {
      return ApiService.patch(USERS_REF, item)
        .then(({ data }) => {
          commit(actionTypes.SAVE_USER, { editMode, payload: data });
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.updateCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.operationError",
              color: "grey",
            },
            { root: true }
          );
          throw new Error(error);
        });
    } else {
      // new registration
      item = {
        ...item,
        roles: ["User"],
      };

      return ApiService.post(USERS_REF, item)
        .then(({ data }) => {
          commit(actionTypes.SAVE_USER, { editMode, payload: data });
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.createCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch(({ response }) => {
          if (response.state === 409) {
            commit(actionTypes.SET_USER_ERROR, "conflict");
            dispatch(
              `ui/${ADD_NOTIFICATION}`,
              {
                text: "messages.conflictUser",
                color: "red accent-2",
              },
              { root: true }
            );
          } else {
            dispatch(
              `ui/${ADD_NOTIFICATION}`,
              {
                text: "messages.operationError",
                color: "grey",
              },
              { root: true }
            );
          }
          // throw new Error(error);
        });
    }
  },
  async [actionTypes.SAVE_USER_PROFILE]({ commit, dispatch }, params) {
    let { item } = params;
    return ApiService.patch(`${USERS_REF}/profile`, item)
      .then(({ data }) => {
        commit(actionTypes.SAVE_USER_PROFILE, data);
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.updateCompleted",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.operationError",
            color: "grey",
          },
          { root: true }
        );
        throw new Error(error);
      });
  },
  async [actionTypes.SAVE_USER_ROLES]({ commit, dispatch }, params) {
    const { id, assignments } = params;
    if (id > 0) {
      return ApiService.patch(`${USERS_REF}/${id}/roles`, { id, assignments })
        .then(({ data }) => {
          commit(actionTypes.SAVE_USER_ROLES, data);
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.updateCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.operationError",
              color: "grey",
            },
            { root: true }
          );
          throw new Error(error);
        });
    }
  },
  async [actionTypes.SAVE_USER_HUBS]({ commit, dispatch }, params) {
    const { id, assignments } = params;
    if (id > 0) {
      return ApiService.patch(`${USERS_REF}/${id}/hubs`, { id, assignments })
        .then(({ data }) => {
          commit(actionTypes.SAVE_USER_HUBS, data);
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.updateCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.operationError",
              color: "grey",
            },
            { root: true }
          );
          throw new Error(error);
        });
    }
  },
  async [actionTypes.DELETE_USER]({ commit, dispatch }, id) {
    const { status } = await ApiService.delete(USERS_REF, id);
    if (status === 200) {
      dispatch(
        `ui/${ADD_NOTIFICATION}`,
        {
          text: "messages.deleteCompleted",
        },
        { root: true }
      );
      commit(actionTypes.CLEAR_SELECTED_USER);
      commit(actionTypes.DELETE_USER, id);
    }
  },
  [actionTypes.SET_SELECTED_USER]({ commit }, user) {
    commit(actionTypes.SET_SELECTED_USER, user);
  },
  [actionTypes.CLEAR_SELECTED_USER]({ commit }) {
    commit(actionTypes.SET_SELECTED_USER);
  },
  [actionTypes.SET_USER_ERROR]({ commit }, params) {
    commit(actionTypes.SET_USER_ERROR, params);
  },
};

// mutations
const mutations = {
  [actionTypes.LOAD_USERS](state) {
    state.isLoading = true;
  },
  [actionTypes.LOAD_USERS_COMPLETED](state, payload) {
    state.users = payload || [];
    state.isLoading = false;
  },
  [actionTypes.LOAD_USER_PROFILE](state) {
    state.isLoading = true;
  },
  [actionTypes.LOAD_USER_PROFILE_COMPLETED](state, payload) {
    if (!payload) {
      return;
    }

    if (state.users && state.users.length > 0) {
      state.users = state.users.map((item) => {
        if (item.username === payload.username) {
          return { ...item, ...payload };
        }
        return item;
      });
    } else {
      state.users = [payload];
    }
    state.isLoading = false;
  },
  [actionTypes.SAVE_USER](state, params) {
    const { editMode, payload } = params;
    if (!payload) {
      return;
    }

    if (editMode) {
      state.users = state.users.map((item) => {
        if (item.id === payload.id) {
          return { ...item, ...payload };
        }
        return item;
      });
    } else {
      state.users = [...state.users, payload];
    }
  },
  [actionTypes.SAVE_USER_PROFILE](state, payload) {
    if (!payload) {
      return;
    }

    if (state.users && state.users.length > 0) {
      state.users = state.users.map((item) => {
        if (item.username === payload.username) {
          return { ...item, ...payload };
        }
        return item;
      });
    } else {
      state.users = [payload];
    }
  },
  [actionTypes.SAVE_USER_ROLES](state, payload) {
    if (payload) {
      state.users = state.users.map((item) => {
        if (item.id === payload.id) {
          return { ...item, roles: payload.roles };
        }
        return item;
      });
    }
  },
  [actionTypes.SAVE_USER_HUBS](state, payload) {
    if (payload) {
      state.users = state.users.map((item) => {
        if (item.id === payload.id) {
          return { ...item, hubs: payload.hubs };
        }
        return item;
      });
    }
  },
  [actionTypes.DELETE_USER](state, id) {
    state.users = state.users.filter((user) => user.id !== id);
  },
  [actionTypes.SET_SELECTED_USER](state, payload) {
    state.selectedUser = payload;
  },
  [actionTypes.CLEAR_SELECTED_USER](state) {
    state.selectedUser = null;
  },
  [actionTypes.SET_USER_ERROR](state, payload) {
    state.error = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
