import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import vant from "./plugins/vant";
import fullscreen from "vue-fullscreen";
import i18n from "./locales";
import ApiService from "./api/api.service";
import util from "./common/helpers/util";
import store from "./store";
import { CHECK_AUTH, LOGOUT } from "./store/actions";
import {
  SHOW_DIALOG,
  CLOSE_DIALOG,
  ADD_NOTIFICATION,
  DISMISS_NOTIFICATION,
  SET_BREADCRUMBS,
} from "./store/actions/type.ui";
// import * as types from "@/common/helpers/types";
import { Authorization, Upload, WebSocket, Validation, Notification } from "./plugins";
import VueApexCharts from "vue-apexcharts";

import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false;
Vue.use(Authorization, { store });
Vue.use(Validation);
Vue.use(Notification, { store });
Vue.use(Upload);
Vue.use(WebSocket);
Vue.use(fullscreen);
Vue.use(VueApexCharts);

// init functions
ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  store
    .dispatch(`auth/${CHECK_AUTH}`)
    .then(() => {
      // (user) => {
      // const routeRoles = to.meta.roles;
      if (to.matched.length === 0) {
        setTimeout(() => {
          const notification = {
            text: "Resource not found",
            actionText: "OK",
            action: () => {
              store.dispatch(`ui/${DISMISS_NOTIFICATION}`);
            },
          };
          store.dispatch(`ui/${ADD_NOTIFICATION}`, notification);
        }, 500);
        router.push({ name: "e404" });
      }

      if (to.meta.resource || to.meta.authorized) {
        if (!store.state.auth.isAuthenticated) {
          router.push({ name: "login" });
        } else {
          const rolesAuthorized = Vue.allowed(to.meta.resource);
          if (!rolesAuthorized && to.name !== "login") {
            if (to.name === "dashboards" && from.name === "login") {
              router.push({ name: "welcome" });
            } else if (to.name === "dashboards" && !from.name) {
              router.push({ name: "login" });
            } else {
              const notification = {
                text: "messages.accessDenied",
                actionText: "OK",
                action: () => {
                  store.dispatch(`ui/${DISMISS_NOTIFICATION}`);
                },
              };
              store.dispatch(`ui/${ADD_NOTIFICATION}`, notification);
              if(from.name) {
                router.go(-1);
              }
            }
          } else {
            next();
          }
        }
      } else if (
        (to.name === "login" || to.name === "register") &&
        store.state.auth.isAuthenticated
      ) {
        const dialog = {
          title: "Logged In",
          text: "You are already logged in. Do you want to log out?",
          actions: [
            {
              text: "Yes",
              click: () => {
                store.dispatch(`auth/${LOGOUT}`);
                store.dispatch(`ui/${CLOSE_DIALOG}`);
                next();
              },
              color: "primary",
            },
            {
              text: "No",
              click: () => {
                if (to.name !== "login") {
                  router.go(-1);
                }
                store.dispatch(`ui/${CLOSE_DIALOG}`);
              },
              color: "primary",
            },
          ],
        };

        store.dispatch(`ui/${SHOW_DIALOG}`, dialog);
      } else {
        next();
      }
    })
    .catch(() => {
      router.push({ name: "login" });
    });
});

router.afterEach((to) => {
  if (to.meta.breadcrumb) {
    store.dispatch("ui/" + SET_BREADCRUMBS, to.meta.breadcrumb, {
      root: true,
    });
  }
});

Vue.mixin({
  methods: {
    ...util,
    changeRTL(val) {
      this.$vuetify.rtl = val;
    },
    viewHeight(mdHeight = 102, gap = 30) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return `height: calc(100vh - ${mdHeight}px);`;
      } else {
        return `height: calc(100vh - ${mdHeight + gap}px);`;
      }
    },
    generateGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0,
              v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
    }
  },
  computed: {
    isMd() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isReadOnly() {
      return !this.$hasRole("Administrator") && !this.$allowed("Editor");
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  vant,
  Authorization,
  Upload,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
