import ApiService from "../../api/api.service";
import * as actionTypes from "../actions/type.downloads";
import { ADD_NOTIFICATION } from "../actions/type.ui";
const DOWNLOAD_REF = "files/download";

// initial state
const state = {
  history: [],
};

// getters
const getters = {
  [actionTypes.GET_DOWNLOAD_HISTORY]: (state) => () => {
    return state.history;
  },
  [actionTypes.GET_DOWNLOADING_STATE]: (state) => (action) => {
    const item = state.history.find(f => f.action === action);
    if(item) {
      return !!item.isDownloading;
    }
    return false;
  },
};

// actions
const actions = {
  async [actionTypes.DOWNLOAD]({ commit, dispatch }, params) {
    const { ctr, action, file } = params;
    let url = `${ctr || DOWNLOAD_REF}/${action}/${file}`;
    if(params.params) {
      url += ApiService.toQueryString(params.params);
    }

    commit(actionTypes.DOWNLOAD, params);
    ApiService.file(url)
      .then((response) => {
        commit(actionTypes.DOWNLOAD_COMPLETED, params);
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file;
        link.click();
      }).catch(() => {
        commit(actionTypes.DOWNLOAD_COMPLETED, params);
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.downloadNotCompleted",
            color: "grey",
          },
          { root: true }
        );
    });
  },
};

// mutations
const mutations = {
  [actionTypes.DOWNLOAD](state, payload) {
    state.history.push({ ...payload, isDownloading: true });
  },
  [actionTypes.DOWNLOAD_COMPLETED](state, payload) {
    state.history = state.history.filter((f) => f.action !== payload.action);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
