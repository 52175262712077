import crud from "@/store/modules/crud";
import feeds from "./store/feeds";

const modules = {
  incidents: crud("Incident", "si", ""),
  feeds,
};

function getDataStores() {
  return modules;
}

export { getDataStores };