import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// "#ff0087"
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#009edb",
        secondary: "#0b2e3c",
        accent: "#014259",
        error: "#e91e63",
        warning: "#ffc107",
        info: "#05daf1",
        success: "#09d380",
      },
    },
  },
});
