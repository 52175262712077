import Vue from "vue";
import Vuex from "vuex";
import organizations from "./modules/orgs";
import users from "./modules/users";
import auth from "./modules/auth";
import roles from "./modules/roles";

import aor from "./modules/aor";

import ui from "./modules/ui";
import areas from "./modules/areas";
import types from "./modules/types";
import downloads from "./modules/downloads";
import options from "./modules/options";

Vue.use(Vuex);

const modules = {
  organizations,
  users,
  auth,
  roles,

  aor,

  ui,
  areas,
  types,
  downloads,
  options,
};

function dynamicModules() {
  let list = {};
  // Define the base directory to search for route.js files
  const moduleFiles = require.context("@/features", true, /\/scripts\/index\.js$/)

  // Iterate over all matching files and call the getRoutes function
  const moduleKeys = moduleFiles.keys();
  moduleKeys.forEach((key) => {
    const module = moduleFiles(key);
    if (module.getDataStores) {
      const featureModules = module.getDataStores();
      list = {...list, ...featureModules}
    }
  })

  return list;
}


export default new Vuex.Store({
  modules: {...modules, ...dynamicModules() },
});
