export const SET_BREADCRUMBS = "setBreadcrumbs";
export const ADD_NOTIFICATION = "addNotification";
export const DISMISS_NOTIFICATION = "dismissNotification";
export const SHOW_DIALOG = "showDialog";
export const SHOW_ALERT = "showAlert";
export const REFRESH_DIALOG = "refreshDialog";
export const CLOSE_DIALOG = "closeDialog";
export const SET_LANGUAGE = "setLanguage";
export const TOGGLE_FULLSCREEN = "toggleFullscreen";
export const SET_LOADING_STATE = "setLoadingState";
export const CLEAR_LOADING_STATE = "clearLoadingState";
export const SET_COMMENTS_ENABLED = "setCommentsEnabled";
export const ADD_TO_MENU_BADGE = "addToMenuBadge";
export const CLEAR_MENU_BADGE = "clearMenuBadge";


// getters
export const GET_LOADING_STATE = "getLoadingState";
export const GET_LOADING_MESSAGE = "getLoadingMessage";
export const GET_ACTIVE_DIALOG = "getActiveDialog";
export const GET_ACTIVE_NOTIFICATION = "getActiveNotification";
export const GET_BREADCRUMBS = "getBreadcrumbs";
export const GET_LANGUAGE = "getLanguage";
export const GET_NOTIFICATIONS = "getNotifications";
export const IS_COMMENTS_ENABLED = "isCommentsEnabled";
export const GET_MENU_BADGES = "getMenuBadges"
export const GET_MENU_BADGES_COUNTER = "getMenuBadgesCounter"