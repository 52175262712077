const links = [
    {
      title: "srs.sitreps",
      resource: "srs:sitreps",
      icon: "mdi-file-cabinet",
      link: "/#/sitreps",
      to: "sitreps",
    },
];

function getMenu() {
  return links;
}

export { getMenu };