const AUTH_PRE = "auth";

export const isAuthenticated = () => {
  return !!window.localStorage.getItem(`${AUTH_PRE}.token`);
};

export const getToken = () => {
  return window.localStorage.getItem(`${AUTH_PRE}.token`);
};

export const getUsername = () => {
  return window.localStorage.getItem(`${AUTH_PRE}.username`);
};

export const getAuthData = () => {
  const token = window.localStorage.getItem(`${AUTH_PRE}.token`);
  const username = window.localStorage.getItem(`${AUTH_PRE}.username`);
  const id = window.localStorage.getItem(`${AUTH_PRE}.id`);
  const organizationId = window.localStorage.getItem(`${AUTH_PRE}.organizationId`);
  const organizationAcronym = window.localStorage.getItem(`${AUTH_PRE}.organizationAcronym`);
  return { token, username, id, organizationId, organizationAcronym };
};

export const getRoles = () => {
  const roles = window.localStorage.getItem(`${AUTH_PRE}.roles`);
  return roles;
};

export const saveAuthData = (token, username, id, organizationId, organizationAcronym) => {
  window.localStorage.setItem(`${AUTH_PRE}.token`, token);
  window.localStorage.setItem(`${AUTH_PRE}.username`, username);
  window.localStorage.setItem(`${AUTH_PRE}.id`, id);
  window.localStorage.setItem(`${AUTH_PRE}.organizationId`, organizationId);
  window.localStorage.setItem(`${AUTH_PRE}.organizationAcronym`, organizationAcronym);
};

export const saveRoles = (roles) => {
  window.localStorage.setItem(`${AUTH_PRE}.roles`, roles);
};

export const deleteAuthData = () => {
  window.localStorage.removeItem(`${AUTH_PRE}.token`);
  window.localStorage.removeItem(`${AUTH_PRE}.username`);
  window.localStorage.removeItem(`${AUTH_PRE}.id`);
  window.localStorage.removeItem(`${AUTH_PRE}.organizationId`);
  window.localStorage.removeItem(`${AUTH_PRE}.organizationAcronym`);
};

export default {
  isAuthenticated,
  getToken,
  getAuthData,
  saveAuthData,
  deleteAuthData,
};
