import ApiService from "../../api/api.service";
import * as actionTypes from "../actions/type.orgs";
import { ADD_NOTIFICATION } from "../actions/type.ui";

const ORGS_REF = "orgs";

// initial state
const state = {
  orgs: [],
  // orgsPagination: {
  //   totalElements: 0,
  //   totalPages: 0,
  //   size: 20,
  //   page: 0,
  // },
  isLoading: false,
  selectedOrg: null,
};

// getters
const getters = {
  [actionTypes.GET_ORGS]: (state) => {
    return state.orgs;
  },
  // [actionTypes.GET_ORGS_PAGINATION]: (state) => () => {
  //   return state.orgsPagination;
  // },
  [actionTypes.GET_ORG_NAMES]: (state) => () => {
    return state.orgs.map((m) => {
      const { id, name } = m;
      return { id, name };
    });
  },
  [actionTypes.GET_ORG_BY_ID]: (state) => (id) => {
    return state.orgs.find((item) => item.id === id);
  },
  [actionTypes.GET_SELECTED_ORG]: (state) => {
    return state.selectedOrg;
  },
};

// actions
const actions = {
  async [actionTypes.LOAD_ORGS]({ commit }, params) {
    commit(actionTypes.LOAD_ORGS);
    return new Promise((resolve, reject) => {
      ApiService.query(`${ORGS_REF}/list`, params)
        .then(({ data }) => {
          commit(actionTypes.LOAD_ORGS_COMPLETED, { data, singleton: params && params.id > 0 });
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
          // throw new Error(error);
        });
    });
  },
  async [actionTypes.SAVE_ORG]({ commit, dispatch }, params) {
    const { editMode, item } = params;
    if(!item) {
      return;
    }

    if (editMode) {
      return ApiService.patch(ORGS_REF, item)
        .then(({ data }) => {
          commit(actionTypes.SAVE_ORG, { editMode, payload: data });
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.updateCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.operationError",
              color: "grey",
            },
            { root: true }
          );
          throw new Error(error);
        });
    } else {
      return ApiService.post(ORGS_REF, item)
        .then(({ data }) => {
          commit(actionTypes.SAVE_ORG, { editMode, payload: data });
          commit(actionTypes.CLEAR_SELECTED_ORG);
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.createCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.operationError",
              color: "grey",
            },
            { root: true }
          );
          throw new Error(error);
        });
    }
  },
  async [actionTypes.DELETE_ORG]({ commit, dispatch }, id) {
    const { status } = await ApiService.delete(ORGS_REF, id);
    if (status === 200) {
      dispatch(
        `ui/${ADD_NOTIFICATION}`,
        {
          text: "messages.deleteCompleted",
        },
        { root: true }
      );
      commit(actionTypes.CLEAR_SELECTED_ORG);
      commit(actionTypes.DELETE_ORG, id);
    }
  },
  [actionTypes.SET_SELECTED_ORG]({ commit }, org) {
    commit(actionTypes.SET_SELECTED_ORG, org);
  },
  [actionTypes.CLEAR_SELECTED_ORG]({ commit }) {
    commit(actionTypes.CLEAR_SELECTED_ORG);
  },
};

// mutations
const mutations = {
  [actionTypes.LOAD_ORGS](state) {
    state.isLoading = true;
  },
  // payload features pagination data
  // [actionTypes.LOAD_ORGS_COMPLETED](
  //   state,
  //   { content, totalElements, totalPages, size, number: page }
  // ) {
  //   state.orgs = content;
  //   state.orgsPagination = { totalElements, totalPages, size, page };
  //   state.isLoading = false;
  // },
  [actionTypes.LOAD_ORGS_COMPLETED](state, payload) {
    if(!payload || !payload.data) {
      return;
    }

    if(payload.singleton && state.orgs && state.orgs.length > 0) {
      state.orgs = [...state.orgs, ...payload.data];
    } else {
      state.orgs = payload.data;
    }
    state.isLoading = false;
  },
  [actionTypes.SAVE_ORG](state, params) {
    const { editMode, payload } = params;
    if(!payload) {
      return;
    }

    if (editMode) {
      // const index = state.orgs.findIndex(f => f.id === payload.id);
      // const item = { ...state.orgs[index], ...payload};
      // state.orgs = [
      //   ...state.orgs.slice(0, index), item, ...state.orgs.slice(index + 1),
      // ];

      state.orgs = state.orgs.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    } else {
      state.orgs = [...state.orgs, payload];
    }
  },
  [actionTypes.DELETE_ORG](state, id) {
    state.orgs = state.orgs.filter((org) => org.id !== id);
  },
  [actionTypes.SET_SELECTED_ORG](state, payload) {
    state.selectedOrg = payload;
  },
  [actionTypes.CLEAR_SELECTED_ORG](state) {
    state.selectedOrg = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
