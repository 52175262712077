// actions & mutations
export const LOAD_FEEDS = "loadFeeds";
export const SYNC_LOAD_FEEDS = "syncAndLoadFeeds";
export const LOAD_FEEDS_COMPLETED = "loadFeedsCompleted";
export const SET_SELECTED_FEED = "setSelectedFeed"
export const CLEAR_SELECTED_FEED = "clearSelectedFeed"

// getters
export const GET_FEEDS = "getFeeds";
export const GET_FEEDS_TOTAL = "getFeedsTotal";
export const GET_SELECTED_FEED = "getSelectedFeed";