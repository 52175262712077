// import mapGetters from "vuex";
export default {
  install(Vue, { store }) {
    const hasRoleFunc = (roleName) => {
      const { user } = store.state.auth;
      const result = user && user.roles && user.roles.includes(roleName);
      return result;
    };
    const authorizedFunc = () => {
      return store.state.auth.isAuthenticated;
    };
    const getUserInfoFunc = () => {
      const { username, organizationId } = store.state.auth.user;
      return { username, organizationId };
    };
    const allowedFunc = (permission) => {
      const { isAuthenticated } = store.state.auth;
      const { roles: aclRoles } = store.state.roles;
      if (!aclRoles || aclRoles.length === 0 || !isAuthenticated) {
        return false;
      }

      const { roles: userRoles } = store.state.auth.user;
      const targetRoles = aclRoles.filter((f) => userRoles.includes(f.name));
      if (targetRoles != null && targetRoles.length > 0) {
        const hasMatch = targetRoles.some(
          (r) =>
            r.permissions != null &&
            r.permissions.some(
              (p) =>
              p.name === permission ||
              p.name.replace(/\s/g, "").toLowerCase() ===
              permission.replace(/\s/g, "").toLowerCase()
        )
        );

        return hasMatch;
      }

      return false;
    };

    // const canFunc = (action, resource, attribute, owner) => {
    //   return store && action && resource && attribute && owner ? true : true;
    //   // const { isAuthenticated } = store.state.auth;
    //   // const { roles: aclRoles, rolesLoadedSuccessfully } = store.state.roles;
    //   // if (
    //   //   !rolesLoadedSuccessfully ||
    //   //   !aclRoles ||
    //   //   aclRoles.length === 0 ||
    //   //   !isAuthenticated
    //   // ) {
    //   //   return false;
    //   // }

    //   // const { roles: userRoles } = store.state.auth.user;
    //   // let restrictions = [];
    //   // for (let r in userRoles) {
    //   //   const acRole = aclRoles.find((acr) => acr.name === userRoles[r]);
    //   //   if (acRole && acRole.restrictions) {
    //   //     const acrRestrictions = acRole.restrictions.map((m) => {
    //   //       return { ...m, parentIndex: r };
    //   //     });
    //   //     restrictions = [...restrictions, ...acrRestrictions];
    //   //   }
    //   // }

    //   // if (restrictions.length > 0) {
    //   //   const tests = restrictions
    //   //     .filter((f) => f.action === action)
    //   //     .map((m) => {
    //   //       // either: resource/attribute is included specifically
    //   //       // or: not particularly listed but included with ALL(*)
    //   //       // and has not been excluded with (!) specifically

    //   //       // exclusion (!) should always be used with ALL(*) operator;
    //   //       // because the default behavior is excluding everything until it is permitted
    //   //       // and there is no point in excluded what's already been excluded
    //   //       // just as there is no point in including a resource specifically
    //   //       // while it's already been included in ALL(*) operator
    //   //       const a =
    //   //         !m.resources ||
    //   //         m.resources.includes(resource) ||
    //   //         (m.resources.includes("*") &&
    //   //           !m.resources.includes(`!${resource}`));

    //   //       const b =
    //   //         !m.attributes ||
    //   //         m.attributes.includes(attribute) ||
    //   //         (m.attributes.includes("*") &&
    //   //           !m.attributes.includes(`!${attribute}`));

    //   //       const c =
    //   //         !m.possession ||
    //   //         (owner &&
    //   //           m.possession &&
    //   //           (m.possession.includes(owner) ||
    //   //             !m.possession.includes(`!${owner}`)));

    //   //       // return { parentIndex: m.parentIndex, value: a && b && c };
    //   //       return a && b && c;
    //   //     });

    //   //   // testing the validity of restriction rules. That is restriction Rules of the
    //   //   // same Role should not conflict each other on the same action and resource
    //   //   // tests.forEach((e) => {
    //   //   //   const conflictA = tests.filter(
    //   //   //     (f) => f.parentIndex === e.parentIndex && e.value
    //   //   //   );
    //   //   //   const conflictB = tests.filter(
    //   //   //     (f) => f.parentIndex === e.parentIndex && !e.value
    //   //   //   );
    //   //   //   if (conflictA.length > 0 && conflictA.length === conflictB.length) {
    //   //   //     console.error(
    //   //   //       `Conflict in rules setup of action(${action}) on resource(${resource}). Restriction rules of the same Role should not conflict each other.`
    //   //   //     );
    //   //   //   }
    //   //   // });

    //   //   // in case of overlapping restrictions inherited from multiple roles
    //   //   // either you remove the previous role from the user to avoid overlapping
    //   //   // otherwise the system will conceder the restriction with the highest priority and ignore the rest
    //   //   return tests.length > 0 ? tests.pop() : true;
    //   // }

    //   // return true;
    // };

    // Vue.can = canFunc;
    Vue.allowed = allowedFunc;
    Vue.hasRole = hasRoleFunc;
    Vue.getUserInfo = getUserInfoFunc;
    Vue.authorized = authorizedFunc;

    Vue.mixin({
      methods: {
        // $can(action, resource, attribute, owner) {
        //   return canFunc(action, resource, attribute, owner);
        // },
        $hasRole(roleName) {
          return hasRoleFunc(roleName);
        },
        $allowed(permission) {
          return allowedFunc(permission);
        },
        $getUserInfo() {
          return getUserInfoFunc();
        },
        $authorized() {
          return authorizedFunc();
        },
      },
    });
  },
};
