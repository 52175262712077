export default {
  route: {
    dashboard: "Dashboard",
    dashboards: "Dashboards",
    orgs: "Organizations",
    org: "Organization",
    orgShort: "Org.",
    settings: "Settings",
    profile: "Profile",
    editor: "Editor",
    ac: "Access Control",
    users: "Users",
    user: "User",
    roles: "Roles",
    hubs: "Hubs",
    role: "Role",
    bulkImport: "Bulk Import",
    notifications: "Notifications",
    reports: "Reports",
    alerts: "Alerts",
    updates: "Updates",
    dataTypes: "Data Types",
    findByOrg: "Find By Organization",

    aor: "AoR",
    srs: "SRS Reports",
  },
  login: {
    title: "Login Form",
    login: "Login",
    logout: "Log Out",
    register: "Register",
    username: "Username",
    usernameOrEmail: "Username or Email",
    password: "Password",
  },
  comments: {
    event: "Event",
    comment: "Comment",
    newComment: "{0} Send New Comment",
    addCommentsAndEvents: "Add Comments and Events...",
    isEvent: "Is Event",
    alertAdmin: "Alert Administrators",
    alertAudit: "Alert Auditors",
    newAlerts: "New Alerts",
  },
  general: {
    id: "ID",
    code: "Code",
    name: "Name",
    nameUk: "Ukranian Name",
    description: "Description",
    details: "Details",
    date: "Date",
    time: "Time",
    year: "Year",
    action: "Action",
    type: "Type",
    title: "Title",
    status: "Status",
    search: "Search",
    filter: "Filter",
    message: "Message",
    export: "Export",
    goBack: "Go Back",
    add: "Add",
    new: "New",
    edit: "Edit",
    save: "Save",
    delete: "Delete",
    preview: "Preview",
    verify: "Verify",
    verified: "Verified",
    verifySubAgreement: "Verify Sub-Agreement",
    verifyProject: "Verify Project",
    deleteRequest: "Delete Request",
    undo: "Undo",
    undoDelete: "Undo Delete",
    validate: "Check Missing/Incorrect Data",
    enable: "Enable",
    disable: "Disable",
    activated: "Activated",
    readonly: "Read Only",
    keepopen: "Keep Open",
    total: "Total",
    grandTotal: "Grand Total",
    page: "Page",
    goto: "Go To",
    all: "All",
    refresh: "Refresh",
    close: "Close",
    closeOthers: "Close Others",
    closeAll: "Close All",
    pleaseSelect: "Please Select",
    remarks: "Remarks",
    comments: "Comments",
    cancel: "Cancel",
    clear: "Clear",
    dismiss: "Dismiss",
    deselect: "Deselect",
    yes: "Yes",
    no: "No",
    okay: "Okay",
    reset: "Reset",
    copyLink: "Copy Link",
    confirm: "Confirm",
    create: "Create",
    created: "Created At",
    updated: "Updated At",
    fileInput: "File Input",
    areas: "Areas",
    totals: "Totals",
    governorates: "Governorates",
    governorate: "Governorate",
    districts: "Districts",
    district: "District",
    subdistricts: "Sub Districts",
    subdistrict: "Sub District",
    hub: "Hub",
    hubs: "Hubs",
    expandAll: "Expand All",
    collapseAll: "Collapse All",
    startDate: "Start Date",
    from: "From",
    to: "To",
    send: "Send",
    subject: "Subject",
    submissionDate: "Submission Date",
    responseDate: "Response Date",
    submission: "Submission",
    response: "Response",
    authorities: "Authorities",
    authority: "Authority",
    recache: "Recache",
    next: "Next",
    finish: "Finish",
    other: "Other",
    otherSpecify: "Other (Specify)",
  },
  dashboard: {
    srsDashboard: "SRS Tracker",
    rnaDashboardDesc: "SRS Tracker Dashboard",
    feedbackCategoriesTitle: "Feedback Categories and Reported Issues",
    informationRequest: "Information Request",
    requestForAssistance: "Request for Assistance",
    complaint: "Complaint",
    feedback: "Feedback",
    rumour: "Rumour",
    reportsByActionsTaken: "Reports by Action Taken",
    reportsByStatus: "Reports By Status",
    reportsBySubCategory: "Reports By Feedback Sub-Category"
  },
  reports: {
    dataTypes: "Data Type Lists",
    dataTypesDescription:
      "For all data type lists that are being used in the various data entry forms in the system.",
    orgs: "Organizations",
    orgsDescription: "Download organizations information.",
    users: "Users",
    usersDescription:
      "Download users information based on your search criteria.",
    usersLog: "Users Log",
    usersLogDescription:
      "Download a list of user actions log based on your search criteria.",

    // srs
    srs: "UNDSS SRS",
    rnaDescription: "Download all SRS complaint/feedback reports",
  },
  enum: {
    organiationType: { un: "UN", nngo: "NNGO", ingo: "INGO", gov: "GOV" },
  },
  org: {
    acronym: "Acronym",
    new: "New Organization",
    edit: "Edit Organization",
  },
  user: {
    email: "Email",
    username: "Username",
    firstName: "First Name",
    lastName: "Last Name",
    position: "Position",
    phone: "Phone",
    avatar: "Avatar",
    avatarFile: "Avatar File",
    organization: "Organiation",
    searchByOrgOrUserDetails: "Search by organization or user details",
    role: "Role",
    roles: "Roles",
    new: "New User",
    edit: "Edit User",
    ac: "Access Control",
    active: "Active",
    skipConfirmEmail: "Skip Email Confirmation",
    updatePassword: "Update Password",
    password: "Password",
    confirmPassword: "Confirm Password",
    newPassword: "New Password",
  },
  aor: {
    hubName: "Hub Name",
    partially: "Partially Controlled",
    fully: "Fully Controlled",
    updateAoR: "Update AoR",
    messages: {
      invalidTitle: "Invalid Target Areas",
      invalid: "Some target governorates/districts are not within the selected area of responsibility, please review your inputs in target governorates and districts section",
    }
  },
  srs: {
    new: "New Report",
    edit: "Edit Report",

    title: "Complaint & Feedbacks Mechanism",
    referenceId: "Ref. Id",
    location: "Location",
    feedback: "Feedback",
    response: "Response",

    organization: "organization",

    complaintDate:"Complaint Date",
    sector: "Sector",
    targetGroup: "Target Group",
    gender: "Gender",
    consent: "Consent",
    ageGroup: "Age Group",
    disability: "Disability",
    feedbackChannel: "Feedback Channel",
    feedbackCategory: "Feedback Category",
    subCategory: "Sub-Category",
    action: "Action",
    actionTaken: "Action Taken",
    responseModality: "Response Modality",
    satisfaction: "Satisfaction",
  },
  role: { new: "New Role", edit: "Edit Role", permissions: "Role Permissions" },
  settings: {
    dataTypes: {
      group: "Group",
      items: "Data Type Items",

      hubType: "Admin Hub Types",

      rnaModality: "SRS Modality Types",
    },
  },
  notifications: {
    // inactiveUsersNotificationDays: "Inactive Users Notification",
    // weeklyUpdatesReminder: "Weekly Updates Reminder",
    // weekDay: "Week Day",
    // hour: "Hour",
    // minute: "Minute",
    // dueDateDeadlineDays: "Due Date Deadline Days",
    // inactiveDays: "Inactive Days",
    sendAlertOnNewReport: "Send alert on new report",
    sendAlertOnReportUpdate: "Send alert on report update",
    sendAlertOnReportDeleteRequest:
      "Send alert on report delete request",
    // sendAlertOnVerificationChange:
    //   "Send alert on sub-agreement verification change",
    sendAlertsByEmail: "Send alerts by email",
    mailingList: "Mailing List",
  },
  weekday: {
    sat: "Saturday",
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
  },
  messages: {
    loading: "Loading Data...",
    loadingOrg: "Loading organization data...",
    initializingOrg: "Initializing new organization...",
    loadingRole: "Loading role data...",
    initializingRole: "Initializing new role...",
    loadingUser: "Loading user data...",
    initializingUser: "Initializing new user...",
    initializingReport: "Initializing report...",
    done: "Operation completed successfully",
    updateDone: "Update completed successfully",
    updateCompleted: "Update operation has completed successfully",
    createCompleted: "Create operation has completed successfully",
    deleteCompleted: "Delete operation has completed successfully",
    deleteRequest: "Delete request has been sent",
    cancelDeleteRequest: "Delete request has been cancelled",
    accessDenied: "Access Denied",
    sessionEnded: "Your session has expired. Please try login again",
    wrongCredentials:
      "The username and/or password might be incorrect or your account might be deactivated",
    wrongInputs: "Wrong inputs",
    invalidConfirmationCode: "Invalid email confirmation code",
    downloadNotCompleted: "Download operation not completed",
    errorConflictUser: "There might be a user with that same email or username",
    updateNotSuccessful: "This update was not successful",
    operationError: "Error while executing this operation",
    conflictUser: "User with the same username or email already existed",
    validation:
      "Incorrect or missing inputs, please check validation messages in the form",
    onlyAuditors:
      "Sorry, only organization-auditors are allowed to perform this action",
    onlyOneSubagr: "Only one Sub-Agreement is allowed per authority",
    onlyOneNceSubage:
      "Only one No-Cost-Extension Sub-Agreement is allowed per authority",
    searchCriteria:
      "Select search criteria or leave it empty to bring all data.",
    checkEmailInbox:
      "An email has been sent to you. please check your email inbox.",
    passwordUpdated: "Password has been reset successfully.",
    passwordUpdatedFailed:
      "Reset password failed. Reset code might be expired.",
    dataChanged:
    "The data in this form has changed, are you sure you want to move away without saving?",
  },
  options: {
    title: "Options",
    authTokens: "Autentication Tokens",
  }
};