import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en'
import ukLocale from './uk'
import _ from "lodash";

// const printLabel = (prefix, obj) => {
//   for(var p in obj) {
//     if(typeof obj[p] === "string") {
//       console.log(`${prefix}.${p},${obj[p]}`);
//     } else if(typeof obj[p] === "object") {
//       printLabel(p, obj[p]);
//     }
//   }
// }
// printLabel("", enLocale);
// printLabel("", arLocale);

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    // add more resources
  },
  uk: {
    ...ukLocale,
    // add more resources
  }
}

function dynamicLocale() {
  let locale = {...messages};
  // Define the base directory to search for route.js files
  const localeFiles = require.context("@/features", true, /\/scripts\/index\.js$/)

  // Iterate over all matching files and call the getRoutes function
  const fileKeys = localeFiles.keys();
  fileKeys.forEach((key) => {
    const module = localeFiles(key);
    if (module.getLocale) {
      const featureLocale = module.getLocale();
      // const en = _.merge(messages.en, featureLocale.default);
      // locale.en = _.merge({...messages, en}, featureLocale.global.en);
      locale = _.merge(messages, featureLocale.global);
    }
  })
  
  console.log(locale);
  return locale;
}

export function getLanguage() {
  const chooseLanguage = localStorage.getItem('language')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  fallbackLocale: 'en',
  // set locale messages
  messages: dynamicLocale(),
})

export default i18n
