import ApiService from "@/api/api.service";
import * as actionTypes from "../actions/type.aor";
import { ADD_NOTIFICATION } from "../actions/type.ui";
import _ from "lodash";

const AOR_REF = "aor";

const state = {
  errors: null,
  aor: [],
  selectedAor: null,
  aorAreLoading: false,
  aorLoadedSuccessfully: false,
};

const getters = {
  [actionTypes.GET_SELECTED_AOR](state) {
    return state.selectedAor;
  },
  [actionTypes.GET_AOR](state) {
    return state.aor;
  },
  [actionTypes.GET_AOR_BY_ID]: (state) => (id) => {
    return state.aor.find((aor) => aor.id === id);
  },
  [actionTypes.GET_AOR_BY_HUB_ID]: (state) => (id) => {
    return state.aor.filter((aor) => aor.hubTypeId === id);
  },
  [actionTypes.GET_AOR_ADMIN1_BY_HUB_ID]: (state) => (idOrIds) => {
    let aor = [];
    const gs = state.aor.map(m => ({ gcode: m.gcode, hubIds: m.aor.map(h => h.hub_type_id)}));
    if (idOrIds && idOrIds instanceof Array) {
      aor = gs.filter((f) => idOrIds.some((s) => f.hubIds.includes(s)));
    } else {
      aor = gs.filter((f) => f.hubIds.includes(idOrIds));
    }
    return _.uniq(aor.map((m) => m.gcode));
  },
  [actionTypes.GET_AOR_ADMIN2_BY_HUB_ID]: (state) => (idOrIds) => {
    let aor = [];
    const ds = state.aor.map(m => ({ pcode: m.pcode, hubIds: m.aor.map(h => h.hub_type_id)}));
    if (idOrIds && idOrIds instanceof Array) {
      aor = ds.filter((f) => idOrIds.some((s) => f.hubIds.includes(s)));
    } else {
      aor = ds.filter((f) => f.hubIds.includes(idOrIds));
    }
    return aor ? aor.map((m) => m.pcode) : [];
  },
  [actionTypes.GET_MY_AOR_ADMIN1]: (state, getters, rootState) => {
    const { hubs } = rootState.auth.user;
    const aor =
      state.aor && hubs && hubs.length > 0
        ? state.aor.filter(
            (f) =>
              f.aor &&
              f.aor.length > 0 &&
              f.aor.some((s) => hubs.includes(s.hub_type_id))
          )
        : state.aor;
    return aor ? _.uniq(aor.map((m) => m.gcode)) : [];
  },
  [actionTypes.GET_MY_AOR_ADMIN2]: (state, getters, rootState) => {
    const { hubs } = rootState.auth.user;
    const aor =
      state.aor && hubs && hubs.length > 0
        ? state.aor.filter(
            (f) =>
              f.aor &&
              f.aor.length > 0 &&
              f.aor.some((s) => hubs.includes(s.hub_type_id))
          )
        : state.aor;
    return aor ? aor.map((m) => m.pcode) : [];
  },
};

const actions = {
  async [actionTypes.LOAD_AOR]({ commit }, params) {
    commit(actionTypes.LOAD_AOR);
    return new Promise((resolve, reject) => {
      ApiService.query(AOR_REF + "/districts", params)
        .then(({ data, status }) => {
          commit(actionTypes.LOAD_AOR_COMPLETED, {
            success: status === 200,
            data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  [actionTypes.SET_AOR]({ commit }, params) {
    commit(actionTypes.LOAD_AOR_COMPLETED, {
      success: params && params.length > 0,
      data: params,
    });
  },
  async [actionTypes.SAVE_AOR]({ commit, dispatch }, params) {
    if (!params) {
      return;
    }

    return ApiService.patch(AOR_REF + "/update", params)
      .then(({ data }) => {
        commit(actionTypes.SAVE_AOR, data);
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.updateCompleted",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: "messages.operationError",
            color: "grey",
          },
          { root: true }
        );
        throw new Error(error);
      });
  },
  async [actionTypes.DELETE_AOR]({ commit, dispatch }, id) {
    const { status } = await ApiService.delete(AOR_REF, id);
    if (status === 200) {
      dispatch(
        `ui/${ADD_NOTIFICATION}`,
        {
          text: "messages.deleteCompleted",
        },
        { root: true }
      );
      commit(actionTypes.CLEAR_SELECTED_AOR);
      commit(actionTypes.DELETE_AOR, id);
    }
  },
  [actionTypes.SET_SELECTED_AOR]({ commit }, org) {
    commit(actionTypes.SET_SELECTED_AOR, org);
  },
  [actionTypes.CLEAR_SELECTED_AOR]({ commit }) {
    commit(actionTypes.CLEAR_SELECTED_AOR);
  },
};

const mutations = {
  [actionTypes.SET_AOR_ERROR](state, error) {
    state.errors = error;
  },
  [actionTypes.LOAD_AOR](state) {
    state.aorAreLoading = true;
  },
  [actionTypes.LOAD_AOR_COMPLETED](state, payload) {
    state.aorLoadedSuccessfully = payload.success;
    state.aorAreLoading = false;
    state.aor = payload.data;
  },
  [actionTypes.SAVE_AOR](state, payload) {
    if (!payload) {
      return;
    }
    state.aor = state.aor.map((m) => (m.pcode === payload.pcode ? payload : m));
  },
  [actionTypes.DELETE_AOR](state, id) {
    state.aor = state.aor.filter((role) => role.id !== id);
  },
  [actionTypes.SET_SELECTED_AOR](state, payload) {
    state.selectedAor = payload;
  },
  [actionTypes.CLEAR_SELECTED_AOR](state) {
    state.selectedAor = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
