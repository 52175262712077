export default {
  route: {
    dashboard: "Панель управления",
    dashboards: "Панели управления",
    orgs: "Организации",
    org: "Организация",
    orgShort: "Орг.",
    settings: "Настройки",
    profile: "Профиль",
    editor: "Редактор",
    ac: "Управление доступом",
    users: "Пользователи",
    user: "Пользователь",
    roles: "Роли",
    hubs: "Хабы",
    role: "Роль",
    bulkImport: "Массовый импорт",
    notifications: "Уведомления",
    reports: "Отчеты",
    alerts: "Оповещения",
    updates: "Обновления",
    dataTypes: "Типы данных",
    findByOrg: "Найти по организации",
    aor: "AoR",
    srs: "Отчеты SRS",
  },
  login: {
    title: "Форма входа",
    login: "Войти",
    logout: "Выйти",
    register: "Регистрация",
    username: "Имя пользователя",
    usernameOrEmail: "Имя пользователя или Email",
    password: "Пароль",
  },
  comments: {
    event: "Событие",
    comment: "Комментарий",
    newComment: "{0} Отправить новый комментарий",
    addCommentsAndEvents: "Добавить комментарии и события...",
    isEvent: "Это событие",
    alertAdmin: "Оповестить администраторов",
    alertAudit: "Оповестить аудиторов",
    newAlerts: "Новые оповещения",
  },
  general: {
    id: "ID",
    code: "Код",
    name: "Название",
    nameUk: "Ukrainian название",
    description: "Описание",
    details: "Детали",
    date: "Дата",
    time: "Время",
    year: "Год",
    action: "Действие",
    type: "Тип",
    title: "Заголовок",
    status: "Статус",
    search: "Поиск",
    filter: "Фильтр",
    message: "Сообщение",
    export: "Экспорт",
    goBack: "Назад",
    add: "Добавить",
    new: "Новый",
    preview: "Предпросмотр",
    edit: "Редактировать",
    save: "Сохранить",
    delete: "Удалить",
    verify: "Проверить",
    verified: "Проверено",
    verifySubAgreement: "Проверить подсоглашение",
    verifyProject: "Проверить проект",
    deleteRequest: "Запрос на удаление",
    undo: "Отменить",
    undoDelete: "Отменить удаление",
    validate: "Проверить отсутствующие/неправильные данные",
    enable: "Включить",
    disable: "Отключить",
    activated: "Активировано",
    readonly: "Только чтение",
    keepopen: "Не закрывать",
    total: "Всего",
    grandTotal: "Общий итог",
    page: "Страница",
    goto: "Перейти к",
    all: "Все",
    refresh: "Обновить",
    close: "Закрыть",
    closeOthers: "Закрыть остальные",
    closeAll: "Закрыть все",
    pleaseSelect: "Пожалуйста, выберите",
    remarks: "Замечания",
    comments: "Комментарии",
    cancel: "Отмена",
    clear: "Очистить",
    dismiss: "Скрыть",
    deselect: "Отменить выбор",
    yes: "Да",
    no: "Нет",
    okay: "ОК",
    reset: "Сбросить",
    copyLink: "Копировать ссылку",
    confirm: "Подтвердить",
    create: "Создать",
    created: "Создано",
    updated: "Обновлено",
    fileInput: "Файл",
    areas: "Зоны",
    totals: "Итоги",
    governorates: "Губернаторства",
    governorate: "Губернаторство",
    districts: "Районы",
    district: "Район",
    subdistricts: "Подрайоны",
    subdistrict: "Подрайон",
    hub: "Хаб",
    hubs: "Хабы",
    expandAll: "Развернуть все",
    collapseAll: "Свернуть все",
    startDate: "Дата начала",
    from: "От",
    to: "До",
    send: "Отправить",
    subject: "Тема",
    submissionDate: "Дата отправки",
    responseDate: "Дата ответа",
    submission: "Отправка",
    response: "Ответ",
    authorities: "Авторитеты",
    authority: "Авторитет",
    recache: "Обновить кэш",
    next: "Следующий",
    finish: "Завершить",
    other: "Другое",
    otherSpecify: "Другое (Уточните)",
  },
  dashboard: {
    srsDashboard: "Отслеживание SRS",
    rnaDashboardDesc: "Панель отслеживания SRS",

    feedbackCategoriesTitle: "Категории обратной связи и сообщенные проблемы",
    informationRequest: "Запрос информации",
    requestForAssistance: "Запрос на помощь",
    complaint: "Жалоба",
    feedback: "Обратная связь",
    rumour: "Слух",
    reportsByActionsTaken: "Отчеты по предпринятым действиям",
    reportsByStatus: "Отчеты по статусу",
    reportsBySubCategory: "Отчеты по подкатегории обратной связи"
  },
  reports: {
    dataTypes: "Списки типов данных",
    dataTypesDescription:
      "Для всех списков типов данных, используемых в различных формах в системе.",
    orgs: "Организации",
    orgsDescription: "Загрузить информацию об организациях.",
    users: "Пользователи",
    usersDescription:
      "Загрузить информацию о пользователях на основе ваших критериев поиска.",
    usersLog: "Журнал пользователей",
    usersLogDescription:
      "Загрузить список журнала действий пользователей на основе ваших критериев поиска.",

    // srs
    srs: "UNDSS SRS",
    rnaDescription: "Загрузить все жалобы/отчеты обратной связи SRS",
  },
  enum: {
    organiationType: { un: "ООН", nngo: "ННГО", ingo: "ИНГО", gov: "Правительственная" },
  },
  org: {
    acronym: "Акроним",
    new: "Новая организация",
    edit: "Редактировать организацию",
  },
  user: {
    email: "Email",
    username: "Имя пользователя",
    firstName: "Имя",
    lastName: "Фамилия",
    position: "Должность",
    phone: "Телефон",
    avatar: "Аватар",
    avatarFile: "Файл аватара",
    organization: "Организация",
    searchByOrgOrUserDetails: "Поиск по организации или деталям пользователя",
    role: "Роль",
    roles: "Роли",
    new: "Новый пользователь",
    edit: "Редактировать пользователя",
    ac: "Управление доступом",
    active: "Активный",
    skipConfirmEmail: "Пропустить подтверждение по email",
    updatePassword: "Обновить пароль",
    password: "Пароль",
    confirmPassword: "Подтвердить пароль",
    newPassword: "Новый пароль",
  },
  aor: {
    hubName: "Название хаба",
    partially: "Частично контролируемый",
    fully: "Полностью контролируемый",
    updateAoR: "Обновить AoR",
    messages: {
      invalidTitle: "Неверные целевые зоны",
      invalid: "Некоторые целевые губернаторства/районы не находятся в выбранной области ответственности. Пожалуйста, проверьте ваши данные в разделе целевых губернаторств и районов.",
    }
  },
  srs: {
    new: "Новый отчет",
    edit: "Редактировать отчет",

    title: "Механизм жалоб и обратной связи",
    referenceId: "Идентификатор ссылки",
    location: "Местоположение",
    feedback: "Обратная связь",
    response: "Ответ",

    organization: "Организация",

    complaintDate:"Дата жалобы",
    sector: "Сектор",
    targetGroup: "Целевая группа",
    gender: "Пол",
    consent: "Согласие",
    ageGroup: "Возрастная группа",
    disability: "Инвалидность",
    feedbackChannel: "Канал обратной связи",
    feedbackCategory: "Категория обратной связи",
    subCategory: "Подкатегория",
    action: "Действие",
    actionTaken: "Предпринятые действия",
    responseModality: "Модальность ответа",
    satisfaction: "Удовлетворенность",
  },
  role: { new: "Новая роль", edit: "Редактировать роль", permissions: "Права роли" },
  settings: {
    dataTypes: {
      group: "Группа",
      items: "Элементы типа данных",

      hubType: "Типы административных хабов",

      rnaModality: "Типы модальности SRS",
    },
  },
  notifications: {
    sendAlertOnNewReport: "Отправить оповещение о новом отчете",
    sendAlertOnReportUpdate: "Отправить оповещение об обновлении отчета",
    sendAlertOnReportDeleteRequest:
      "Отправить оповещение о запросе на удаление отчета",
    sendAlertsByEmail: "Отправлять оповещения по email",
    mailingList: "Список рассылки",
  },
  weekday: {
    sat: "Суббота",
    sun: "Воскресенье",
    mon: "Понедельник",
    tue: "Вторник",
    wed: "Среда",
    thu: "Четверг",
    fri: "Пятница",
  },
  messages: {
    loading: "Загрузка данных...",
    loadingOrg: "Загрузка данных об организации...",
    initializingOrg: "Инициализация новой организации...",
    loadingRole: "Загрузка данных о роли...",
    initializingRole: "Инициализация новой роли...",
    loadingUser: "Загрузка данных пользователя...",
    initializingUser: "Инициализация нового пользователя...",
    initializingReport: "Инициализация отчета...",
    done: "Операция успешно завершена",
    updateDone: "Обновление успешно завершено",
    updateCompleted: "Операція оновлення успішно завершена",
    createCompleted: "Операція створення успішно завершена",
    deleteCompleted: "Операція видалення успішно завершена",
    deleteRequest: "Запит на видалення надіслано",
    cancelDeleteRequest: "Запит на видалення скасовано",
    accessDenied: "Доступ заборонено",
    sessionEnded: "Ваша сесія закінчилася. Будь ласка, увійдіть знову",
    wrongCredentials:
      "Ім'я користувача та/або пароль можуть бути невірними або ваш обліковий запис може бути вимкнений",
    wrongInputs: "Невірні вхідні дані",
    invalidConfirmationCode: "Невірний код підтвердження електронної пошти",
    downloadNotCompleted: "Операція завантаження не завершена",
    errorConflictUser: "Можливо, існує користувач з такою ж електронною поштою або ім'ям користувача",
    updateNotSuccessful: "Це оновлення не було успішним",
    operationError: "Помилка під час виконання цієї операції",
    conflictUser: "Користувач з таким самим ім'ям користувача або електронною поштою вже існує",
    validation:
      "Невірні або відсутні вхідні дані, будь ласка, перевірте повідомлення про перевірку у формі",
    onlyAuditors:
      "Вибачте, тільки аудитори організації мають право виконувати цю дію",
    onlyOneSubagr: "Дозволено тільки одне під-угода на владу",
    onlyOneNceSubage:
      "Дозволено тільки одну під-угода про безкоштовне розширення на владу",
    searchCriteria:
      "Виберіть критерії пошуку або залиште його порожнім, щоб отримати всі дані.",
    checkEmailInbox:
      "Електронний лист був відправлений вам. Будь ласка, перевірте свою поштову скриньку.",
    passwordUpdated: "Пароль успішно скинутий.",
    passwordUpdatedFailed:
      "Не вдалося скинути пароль. Термін дії коду скидання може закінчитися.",
    dataChanged:
      "Дані в цій формі були змінені. Ви впевнені, що хочете перейти без збереження?",
  },
};