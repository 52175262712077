import ApiService from "@/api/api.service";
import Vue from 'vue'

const ApiActions = {
  async load(commit, url, action, params, options) {
    if(commit && action && !options?.skipInitMutation) {
      commit(action);
    }
    
    return new Promise((resolve, reject) => {
      ApiService.query(url, params)
        .then(({ data, status }) => {
          if(commit && action && !options?.skipCompletedMutation) {
              commit(action + "Completed", {
              success: status === 200,
              data: data?.content || data,
              params,
              total: data?.totalElements || 0,
              singleton: params && params.id > 0,
              });
          }

          if (options?.onSuccess) {
            options.onSuccess(data);
          }

          resolve(data);
        })
        .catch(({ response }) => {
          if(commit && action) {
              commit(action + "Completed", {
              success: false,
              data: [],
              params,
            });
          }
          
          if (options?.onError) {
            options.onError(response);
          }

          reject(response);
        });
    });
  },

  async save(commit, url, action, params, options) {
    if (!params) {
      return;
    }

    const { editMode, item } = params;

    if (editMode) {
      return ApiService.patch(url, item)
        .then(({ data }) => {
          if (commit && action) {
            commit(action, { editMode, payload: data, params });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.updateDone", "success");
          }
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(error);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          throw new Error(error);
        });
    } else {
      return ApiService.post(url, item)
        .then(({ data }) => {
          if(commit && action) {
            commit(action, { editMode, payload: data, params });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(error);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          throw new Error(error);
        });
    }
  },

  async add(commit, url, actionName, params, options) {
    if (!params) {
      return;
    }

    return this.action(commit, url, actionName, params, options);
  },

  async action(commit, url, actionName, params, options) {
    return ApiService.post(url, params)
      .then(({ data, status }) => {
        if (commit && actionName) {
          commit(actionName, {
            success: status === 200,
            payload: data,
            params,
         });
        }

        if (options && options.onSuccess) {
          options.onSuccess(data);
        }

        if (!options || !options.silent) {
          Vue.popup(
            (options && options.successMessage) || "messages.done",
            "success"
          );
        }
      })
      .catch((error) => {
        if (options && options.onError) {
          options.onError(error);
        }

        if (!options || !options.silent) {
          Vue.popup(
            (options && options.errorMessage) || "messages.operationError",
            "error"
          );
        }
      });
  },

  async update(commit, url, action, params, options) {
    return new Promise((resolve, reject) => {
      ApiService.patch(url, params)
        .then(({ data, status }) => {
          if (commit && action) {
            commit(action, { success: status === 200, payload: data, params });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.updateDone", "success");
          }
          resolve(data);
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(error);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          reject(error);
        });
    });
  },

  async delete(commit, url, action, id, options) {
    return new Promise((resolve, reject) => {
      ApiService.delete(url, id)
        .then(({ data, status }) => {
          if (commit && action) {
            commit(action, { success: status === 200, payload: id });
          }

          if (options && options.onSuccess) {
            options.onSuccess(data);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.deleteCompleted", "black");
          }
          resolve(data);
        })
        .catch((error) => {
          if (options && options.onError) {
            options.onError(error);
          }

          if (!options || !options.silent) {
            Vue.popup("messages.operationError", "error");
          }
          reject(error);
        });
    });
  },
};

export default ApiActions;
