import { ADD_NOTIFICATION } from "@/store/actions/type.ui.js";
// import i18n from "@/locales";

const colors = {
  error: "red accent-2",
  warning: "orange",
  success: "success",
  info: "primary",
};

export default {
  install(Vue, { store }) {
    const popupFunc = (data, type) => {
      if(typeof data === "string") {
        store.dispatch(
          `ui/${ADD_NOTIFICATION}`,
          {
            text: data,
            color: colors[type],
            timeout: 3500,
          },
          { root: true }
        );
      } else if(data instanceof Array && !!data[0]) {
        for(let d in data) {
          store.dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: data[d].message,
              textParams: data[d].attributes,
              color: colors[data[d].type],
              timeout: 3500,
            },
            { root: true }
          );
        }
      }
    }

    const notifyFunc = (data) => {
      store.dispatch(
        `ui/${ADD_NOTIFICATION}`,
        data,
        { root: true }
      );
    }

    Vue.popup = popupFunc;
    Vue.notify = notifyFunc;

    Vue.mixin({
      methods: {
        $popup(data, type) {
          popupFunc(data, type);
        },
        $notify(data) {
          notifyFunc(data);
        },
      },
    });
  }
}