const globalLocale = {
  "uk": {
    "srs": {
      "incident": "Інцидент",
      "incidents": "Інциденти",
      "newsFeed": "Стрічка новин",
      "map": "Карта",
      "downloadDescription": "Завантажити всі звіти про інциденти",
      "na": "Н/Д",
      "other": "Інше",
      "otherValue": "Інше значення",
      "comment": "Коментар",
      "dateTime": "Дата/час",
      "details": "Деталі",
    },
    "si": {
      "incidentType": "Тип інциденту",
      "area": "Ділянка",
      "dateTimeOfIncident": "Дата/час інциденту",
      "dateOfIncident": "Дата інциденту",
      "timeOfIncident": "Час інциденту",
      "oblast": "Область",
      "raion": "Район",
      "hromada": "Громада",
      "settlement": "Поселення",
      "gcaNgca": "GCA/NGCA",
      "latitude": "Широта",
      "longitude": "Довгота",
      "narrativeWereReported": "Опис / були повідомлені",

      "weaponModel": "Кількість Модель зброї",
      "artilleryUnidentified": "Кількість Артилерія (невизначено)",
      "mlrs": "Кількість БМЛР",
      "tank": "Кількість Танк",
      "mortar": "Кількість Міномет",
      "missiles": "Кількість Ракети",
      "airStrike": "Кількість Повітряний удар",
      "interceptedByAirDefence": "Кількість Перехоплено ППО",
      "clusterMunitions": "Кількість Кластерні боєприпаси",
      "explosions": "Кількість Вибухи",
      "heavyMachineGun": "Кількість Важкий кулемет",
      "smallArms": "Кількість Дрібна зброя",
      "uav": "Кількість БПЛА",
      "erwUxo": "Кількість РРВ/НВВ",
      "iedLandmine": "Кількість ІГВ/Мін",
      "chemical": "Кількість Хімічні речовини",
      "nuclear": "Кількість Ядерні",
      "privateHomes": "Кількість Приватні будинки",
      "multistoreResidentialBuilding": "Кількість Багатоповерхові житлові будинки",
      "medicalFacility": "Кількість Медичні заклади",
      "educationalFacility": "Кількість Освітні заклади",
      "unspecifiedCivInfrastructure": "Кількість Невизначена цивільна інфраструктура",
      "industrialPremises": "Кількість Промислові приміщення",
      "privateCompany": "Кількість Приватні компанії",
      "agriculturalObjects": "Кількість Сільськогосподарські об'єкти",
      "railroad": "Кількість Залізниця",
      "bridges": "Кількість Мости",
      "waterSupply": "Кількість Водопостачання",
      "nuclearPowerPlant": "Кількість Атомна електростанція",
      "electricPlantStation": "Кількість Електростанція",
      "powerLines": "Кількість Лінії електропередач",
      "dams": "Кількість Греблі",
      "gasLines": "Кількість Кількість Газопроводи",
      "ammunitionDepot": "Кількість Сховище боєприпасів",
      "militaryBase": "Кількість Військова база",
      "civilianFatalities": "Кількість Цивільні загиблі",
      "civilianInjuries": "Кількість Цивільні поранені",
      "polSESUFatalities": "Кількість Поліцейські, особи СЕСУ, загиблі",
      "polSESUInjured": "Кількість Поліцейські, особи СЕСУ, поранені",

      "russianArea": "Російська територія",
      
      "source": "Джерело",
      "reportedBy": "Повідомлено",
      "shiftSign": "Підпис зміни",
      "shiftDate": "Дата зміни",
      "shift": "Зміна",
      "IncidentForm": "Форма інциденту",
      "reportInitialData": "Початкові дані про звіт",
      "typeOfArms": "Тип зброї",
      "civilianInfrastructure": "Цивільна інфраструктура",
      "military": "Військовий",
      "casualties": "Втрати",

      "frontline": "Фронтова лінія",

      "feed": "Стрічка новин",
      "locations": "Локації",
      "similarity": "Схожість",
      "matchDegree": "Ступінь відповідності",

      "channel": "Канал",
      "nom": "Останні повідомлення для обробки",
      "min_sim": "Мінімальна відповідність"
    }
  },
  "en": {
    "srs": {
      "incident": "Incident",
      "incidents": "Incidents",
      "newsFeed": "News Feed",
      "map": "Map",
      "downloadDescription": "Download all Incidents reports",
      "na": "N/A",
      "other": "Other",
      "otherValue": "Other Value",
      "comment": "Comment",
      "dateTime": "Date/Time",
      "details": "Details",
    },
    "si": {
      "reportsByIncidentType": "Reports by Incident Type",
      "incidentType": "Incident Type",
      "area": "Area",
      "dateTimeOfIncident": "Date/time of incident",
      "dateOfIncident": "Date of incident",
      "timeOfIncident": "Time of incident",
      "oblast": "Oblast",
      "raion": "Raion",
      "hromada": "Hromada",
      "settlement": "Settlement",
      "gcaNgca": "GCA/NGCA",
      "latitude": "Latitude",
      "longitude": "Longitude",
      "narrativeWereReported": "Narrative / were reported",

      "weaponModel": "# of Weapon Model",
      "artilleryUnidentified": "# of Artillery Unidentified",
      "mlrs": "# of MLRS",
      "tank": "# of Tank",
      "mortar": "# of Mortar",
      "missiles": "# of Missiles",
      "airStrike": "# of Air strike",
      "interceptedByAirDefence": "# of Intercepted by Air Defence",
      "clusterMunitions": "# of Cluster Munitions",
      "explosions": "# of Explosions",
      "heavyMachineGun": "# of Heavy Machine Gun",
      "smallArms": "# of Small Arms",
      "uav": "# of UAV",
      "erwUxo": "# of ERW/UXO",
      "iedLandmine": "# of IED/Landmine",
      "chemical": "# of Chemical",
      "nuclear": "# of Nuclear",
      "privateHomes": "# of Private Homes",
      "multistoreResidentialBuilding": "# of Multistore Residential Building",
      "medicalFacility": "# of Medical Facility",
      "educationalFacility": "# of Educational Facility",
      "unspecifiedCivInfrastructure": "# of Unspecified Civ. Infrastructure",
      "industrialPremises": "# of Industrial Premises",
      "privateCompany": "# of Private Company",
      "agriculturalObjects": "# of Agricultural Objects",
      "railroad": "# of Railroad",
      "bridges": "# of Bridges",
      "waterSupply": "# of Water Supply",
      "nuclearPowerPlant": "# of Nuclear Power Plant",
      "electricPlantStation": "# of Electric Plant/Station",
      "powerLines": "# of Power Lines",
      "dams": "# of Dams",
      "gasLines": "# of Gas Lines",
      "ammunitionDepot": "# of Ammunition Depot",
      "militaryBase": "# of Military Base",
      "civilianFatalities": "# of Civilian Fatalities",
      "civilianInjuries": "# of Civilian Injuries",
      "polSESUFatalities": "# of Pol, SESU Fatalities",
      "polSESUInjured": "# of Pol, SESU Injured",

      "russianArea": "Russian Area",

      "source": "Source",
      "reportedBy": "Reported By",
      "shiftSign": "Shift Sign",
      "shiftDate": "Shift Date",
      "shift": "Shift",
      "IncidentForm": "Incident Form",
      "reportInitialData": "Report Initial Data",
      "typeOfArms": "Type of Arms",
      "civilianInfrastructure": "Civilian Infrastructure",
      "military": "Military",
      "casualties": "Casualties",

      "frontline": "Frontline",

      "feed": "Feed",
      "locations": "Locations",
      "similarity": "Similarity",
      "matchDegree": "Match Degree",

      "channel": "Channel",
      "nom": "Latest Messages To Be Processed",
      "min_sim": "Minimum Match",
    }
  }
};

function getLocale() {
  return {
    global: globalLocale
  };
}

export { getLocale };