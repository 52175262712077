import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./auth.service";

const API_URL = process.env.ROOT_API;
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
    Vue.axios.defaults.headers = {
      "Content-type": "application/json"
    };
    Vue.axios.interceptors.request.use(
      function(config) {
        const token = JwtService.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function(err) {
        return Promise.reject(err);
      }
    );
  },

  query(resource, params) {
    const url = params ? `${resource}/${this.toQueryString(params)}` : resource;
    console.log(url);
    return Vue.axios.get(url);
    // .catch((error) => {
    //   throw new Error({ response: `ApiService [${resource}] ${error}`, error });
    // });
  },

  get(resource, slug) {
    const url = slug ? `${resource}/${slug}` : resource;
    return Vue.axios.get(url);
    // .catch((error) => {
    //   throw new Error({ response: `ApiService [${resource}] ${error}`, error });
    // });
  },

  file(resource, slug) {
    const url = slug ? `${resource}/${slug}` : resource;
    return Vue.axios.get(url, { responseType: 'blob' });
  },

  post(resource, params) {
    return Vue.axios.post(resource, params);
  },
  put(resource, params) {
    return Vue.axios.put(resource, params);
    //  else {
    //   throw new Error({ response: 
    //     `[INPUTS] ApiService [${resource}] ${params.toQueryString()}`
    //   });
    // }
  },
  patch(resource, params) {
    return Vue.axios.patch(resource, params);
    //  else {
    //   throw new Error({ response: 
    //     `[INPUTS] ApiService [${resource}] ${params.toQueryString()}`
    //   });
    // }
  },

  delete(resource, id) {
    const url = `${resource}/${id}`;
    return Vue.axios.delete(url);
    // .catch((error) => {
    //   throw new Error({ response: `ApiService [${resource}] ${error}`, error });
    // });
  },

  deleteWithParams(resource, params) {
    const url = `${resource}/${this.toQueryString(params)}`;
    return Vue.axios.delete(url);
    // .catch((error) => {
    //   throw new Error({ response: `ApiService [${resource}] ${error}`, error });
    // });
  },

  toQueryString(params) {
    let qs = [];
    for (let p in params) {
      if(params[p]) {
        qs.push(`${p}=${params[p]}`);
      }
    }
    return `?${qs.join("&")}`;
  },
};

export default ApiService;
