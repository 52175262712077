import ApiService from "@/api/api.service";
import * as actionTypes from "../actions/type.roles";
import { ADD_NOTIFICATION } from "../actions/type.ui";

const ROLES_REF = "roles";

const state = {
  errors: null,
  roles: [],
  selectedRole: null,
  rolesAreLoading: false,
  rolesLoadedSuccessfully: false,
  hubs: [],
  hubsAreLoading: false,
  hubsLoadedSuccessfully: false,
  permissions: [],
  permissionsAreLoading: false,
  permissionsLoadedSuccessfully: false,
};

const getters = {
  [actionTypes.GET_SELECTED_ROLE](state) {
    return state.selectedRole;
  },
  [actionTypes.GET_ROLES](state) {
    return state.roles;
  },
  [actionTypes.GET_HUBS](state) {
    return state.hubs;
  },
  [actionTypes.GET_PERMISSIONS](state) {
    return state.permissions;
  },
  [actionTypes.GET_ROLE_NAMES]: (state) => () => {
    return state.roles.map((m) => {
      const { id, name } = m;
      return { id, name };
    });
  },
  [actionTypes.GET_ROLE_BY_ID]: (state) => (id) => {
    return state.roles.find((role) => role.id === id);
  },
};

const actions = {
  async [actionTypes.LOAD_ROLES]({ commit }, params) {
    commit(actionTypes.LOAD_ROLES);
    return new Promise((resolve, reject) => {
      ApiService.query(ROLES_REF, params)
        .then(({ data, status }) => {
          commit(actionTypes.LOAD_ROLES_COMPLETED, {
            success: status === 200,
            data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.LOAD_HUBS]({ commit }, params) {
    commit(actionTypes.LOAD_HUBS);
    return new Promise((resolve, reject) => {
      ApiService.query(ROLES_REF + "/hubs", params)
        .then(({ data, status }) => {
          commit(actionTypes.LOAD_HUBS_COMPLETED, {
            success: status === 200,
            data,
          });
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  async [actionTypes.LOAD_PERMISSIONS]({ commit }, params) {
    commit(actionTypes.LOAD_PERMISSIONS);
    return new Promise((resolve, reject) => {
      ApiService.query(`${ROLES_REF}/permissions`, params)
        .then(({ data }) => {
          commit(actionTypes.LOAD_PERMISSIONS_COMPLETED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          // throw new Error(response);
        });
    });
  },
  [actionTypes.SET_ROLES]({ commit }, params) {
    commit(actionTypes.LOAD_ROLES_COMPLETED, {
      success: params && params.length > 0,
      data: params,
    });
  },
  async [actionTypes.SAVE_ROLE]({ commit, dispatch }, params) {
    if (!params) {
      return;
    }

    const { editMode, item } = params;
    if(item.name === "Administrator") {
      return;
    }

    if (editMode) {
      return ApiService.patch(ROLES_REF, item)
        .then(({ data }) => {
          commit(actionTypes.SAVE_ROLE, { editMode, payload: data });
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.updateCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.operationError",
              color: "grey",
            },
            { root: true }
          );
          throw new Error(error);
        });
    } else {
      return ApiService.post(ROLES_REF, item)
        .then(({ data }) => {
          commit(actionTypes.SAVE_ROLE, { editMode, payload: data });
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.createCompleted",
              color: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.operationError",
              color: "grey",
            },
            { root: true }
          );
          throw new Error(error);
        });
    }
  },
  async [actionTypes.DELETE_ROLE]({ commit, dispatch }, id) {
    const { status } = await ApiService.delete(ROLES_REF, id);
    if (status === 200) {
      dispatch(
        `ui/${ADD_NOTIFICATION}`,
        {
          text: "messages.deleteCompleted",
        },
        { root: true }
      );
      commit(actionTypes.CLEAR_SELECTED_ROLE);
      commit(actionTypes.DELETE_ROLE, id);
    }
  },
  [actionTypes.SET_SELECTED_ROLE]({ commit }, org) {
    commit(actionTypes.SET_SELECTED_ROLE, org);
  },
  [actionTypes.CLEAR_SELECTED_ROLE]({ commit }) {
    commit(actionTypes.CLEAR_SELECTED_ROLE);
  },
};

const mutations = {
  [actionTypes.SET_ROLE_ERROR](state, error) {
    state.errors = error;
  },
  [actionTypes.LOAD_ROLES](state) {
    state.rolesAreLoading = true;
  },
  [actionTypes.LOAD_ROLES_COMPLETED](state, payload) {
    state.rolesLoadedSuccessfully = payload.success;
    state.rolesAreLoading = false;
    state.roles = payload.data;
  },
  [actionTypes.LOAD_HUBS](state) {
    state.hubsAreLoading = true;
  },
  [actionTypes.LOAD_HUBS_COMPLETED](state, payload) {
    state.hubsLoadedSuccessfully = payload.success;
    state.hubsAreLoading = false;
    state.hubs = payload.data;
  },
  [actionTypes.LOAD_PERMISSIONS](state) {
    state.permissionsAreLoading = true;
  },
  [actionTypes.LOAD_PERMISSIONS_COMPLETED](state, payload) {
    state.permissionsAreLoading = false;
    state.permissions = payload;
  },
  [actionTypes.SAVE_ROLE](state, params) {
    const { editMode, payload } = params;
    if (!payload) {
      return;
    }

    if (editMode) {
      state.roles = state.roles.map((role) => {
        if (role.id === payload.id) {
          return { ...role, ...payload };
        }
        return role;
      });
    } else {
      state.roles = [...state.roles, payload];
    }
  },
  [actionTypes.DELETE_ROLE](state, id) {
    state.roles = state.roles.filter((role) => role.id !== id);
  },
  [actionTypes.SET_SELECTED_ROLE](state, payload) {
    state.selectedRole = payload;
  },
  [actionTypes.CLEAR_SELECTED_ROLE](state) {
    state.selectedRole = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
