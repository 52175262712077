<template>
  <v-app id="trackingsystem">
    <router-view></router-view>
    <v-dialog
      v-if="!!getActiveDialog"
      v-model="getActiveDialog.show"
      :max-width="getActiveDialog.width ? getActiveDialog.width : 330"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :persistent="getActiveDialog.persistent"
    >
      <v-card>
        <v-toolbar dense short flat :color="getActiveDialog.color ? getActiveDialog.color : 'primary'">
          <v-toolbar-title class="white--text">
            {{ $t(getActiveDialog.title) }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-3">
          {{ getActiveDialog.text }}
          <component
            ref="dialogComponent"
            v-if="!!getActiveDialog.component"
            :is="getActiveDialog.component"
            v-model="getActiveDialog.params"
          />
          <!-- @update:value="(value) => (dialogModel = value)" -->
          <!-- :params="getActiveDialog.params" -->
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-alert
            v-if="
              getActiveDialog.params &&
                getActiveDialog.params.validated &&
                !getActiveDialog.params.valid
            "
            border="left"
            colored-border
            type="error"
            dense
            class="body-2"
          >
            Incorrect or missing, please check validation messages.
          </v-alert>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(action, i) in getActiveDialog.actions"
            :key="i"
            :color="`${action.color} darken-1`"
            text
            @click="dialogButtonAction(action, getActiveDialog.params)"
          >
            {{ $t(action.text) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="getActiveNotification.show"
      :timeout="getActiveNotification.timeout"
      :color="getActiveNotification.color"
      :top="!getActiveNotification.alert"
      :left="getActiveNotification.alert"
      :bottom="getActiveNotification.alert"
      :outlined="getActiveNotification.alert"
      :dark="!getActiveNotification.alert"
      :text="getActiveNotification.alert"
      :vertical="getActiveNotification.alert"
      multi-line
      width="300"
    >
      {{ getActiveNotification.skipTranslation ? getActiveNotification.text : $t(getActiveNotification.text) }}
      <v-btn
        tile
        text
        @click="notificationAction(getActiveNotification.action)"
        v-if="!!getActiveNotification.actionText"
      >
        {{ getActiveNotification.actionText }}
      </v-btn>
    </v-snackbar>

    <!-- <div class="app-tag">
      <span> Testing Mode </span>
    </div> -->
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      dialogModel: {},
    };
  },
  computed: {
    ...mapGetters("ui", ["getActiveDialog", "getActiveNotification"]),
  },
  methods: {
    ...mapActions("ui", ["refreshDialog", "dismissNotification"]),
    dialogButtonAction(action, params) {
      if (action.validate) {
        this.$refs.dialogComponent.validate();
        setTimeout(() => action.click(params), 500);
        params.validated = true;
        this.refreshDialog();
      } else {
        action.click(params);
      }
    },
    notificationAction(action) {
      action();
      this.dismissNotification();
    }
  },
};
</script>

<style>
@import "./assets/styles.css";
</style>
