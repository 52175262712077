import crud from "@/store/modules/crud";

const modules = {
  sitreps: crud("Sitrep", "sitrep", "")
};

function getDataStores() {
  return modules;
}

export { getDataStores };