// access control actions
export const SAVE_AOR = "saveAor";
export const LOAD_AOR = "loadAor";
export const LOAD_AOR_COMPLETED = "loadAorCompleted";
export const SET_AOR = "setAor";
export const DELETE_AOR = "deleteAor";
export const SET_SELECTED_AOR = "setSelectedAor";
export const CLEAR_SELECTED_AOR = "clearSelectedAor";
export const SET_AOR_ERROR = "setAorError";

export const GET_SELECTED_AOR = "getSelectedAor";
export const GET_AOR = "getAor";
export const GET_AOR_BY_ID = "getAorById";
export const GET_AOR_BY_HUB_ID = "getAorByHubTypeId";
export const GET_AOR_ADMIN1_BY_HUB_ID = "getAorAdmin1ByHubId"
export const GET_AOR_ADMIN2_BY_HUB_ID = "getAorAdmin2ByHubId"
export const GET_MY_AOR_ADMIN1 = "getMyAorAdmin1";
export const GET_MY_AOR_ADMIN2 = "getMyAorAdmin2";