import ApiService from "@/api/api.service";
import AuthService from "@/api/auth.service";
import * as actionTypes from "../actions/type.auth";
import { ADD_NOTIFICATION } from "../actions/type.ui";
import { CLEAR_SRS } from "../actions/type.srs";
import { SET_ROLES } from "../actions/type.roles";

const AUTH_REF = "auth";

const state = {
  errors: null,
  user: AuthService.getAuthData(),
  isAuthenticated: AuthService.isAuthenticated(),
};

const getters = {
  [actionTypes.GET_CURRENT_USER](state) {
    return state.user;
  },
  [actionTypes.GET_CURRENT_USER_ID](state) {
    return state.user ? state.user.id : null;
  },
  [actionTypes.GET_USER_ORG_ID](state) {
    return state.user.organizationId;
  },
  [actionTypes.Is_AUTHONITICATED](state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [actionTypes.LOGIN]({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      // for testing only
      ApiService.post(`${AUTH_REF}/login`, credentials)
        .then(({ data }) => {
          const {
            token,
            id,
            username,
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
            acr,
          } = data;
          const user = {
            token,
            id,
            username,
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
          };
          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, acr, { root: true });
          resolve(user);
        })
        .catch(() => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.wrongCredentials",
              color: "grey",
              timeout: 10000,
            },
            { root: true }
          );
          reject();
        });
    });
  },
  [actionTypes.LOGOUT]({ commit, dispatch }) {
    dispatch(`srs/${CLEAR_SRS}`, null, { root: true });
    commit(actionTypes.PURGE_AUTH);
    
  },
  [actionTypes.REGISTER]({ commit, dispatch }, params) {
    params = {
      ...params,
      roles: ["User"],
    };

    return new Promise((resolve, reject) => {
      ApiService.post(`${AUTH_REF}/register`, params)
        .then(({ data }) => {
          const {
            token,
            id,
            username,
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
            acr,
          } = data;
          const user = {
            token,
            id,
            username,
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
          };
          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, acr, { root: true });
          resolve(user);
        })
        .catch((error) => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.errorConflictUser",
              color: "grey",
            },
            { root: true }
          );
          const { response } = error;
          if (response) {
            commit(actionTypes.SET_AUTH_ERROR, response);
            reject(response);
          } else {
            reject(error);
          }
        });
    });
  },
  [actionTypes.CHECK_AUTH]({ commit, dispatch }) {
    if (AuthService.isAuthenticated()) {
      // TODO: in case of adding offline-first approach we need to
      // check connectivity otherwise get from local storage
      return new Promise((resolve, reject) => {
        ApiService.post(`${AUTH_REF}/check`)
          .then(({ data }) => {
            const {
              email,
              firstName,
              lastName,
              organizationId,
              organizationAcronym,
              roles,
              hubs,
              acr,
            } = data;
            const user = {
              email,
              firstName,
              lastName,
              organizationId,
              organizationAcronym,
              roles,
              hubs,
            };
            commit(actionTypes.SET_AUTH, { user, check: true });
            dispatch(`roles/${SET_ROLES}`, acr, { root: true });
            resolve(user);
          })
          .catch((error) => {
            const { response } = error;
            if (response) {
              commit(
                actionTypes.SET_AUTH_ERROR,
                `${response.data.message} | Path = (${response.data.path})`
              );
              if (response.status === 403) {
                commit(actionTypes.PURGE_AUTH);
                dispatch(
                  `ui/${ADD_NOTIFICATION}`,
                  {
                    text: "messages.sessionEnded",
                    color: "red accent-2",
                  },
                  { root: true }
                );
              }
              reject(response);
            } else {
              reject(error);
            }
          });
      });
    } else {
      commit(actionTypes.PURGE_AUTH);
    }
  },
  [actionTypes.CONFIRM_EMAIL]({ commit, dispatch }, code) {
    return new Promise((resolve, reject) => {
      // for testing only
      ApiService.post(`${AUTH_REF}/confirm/${code}`)
        .then(({ data }) => {
          const {
            token,
            id,
            username,
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
            acr,
          } = data;
          const user = {
            token,
            id,
            username,
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
          };
          commit(actionTypes.SET_AUTH, { user });
          dispatch(`roles/${SET_ROLES}`, acr, { root: true });
          resolve(user);
        })
        .catch(() => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.invalidConfirmationCode",
              color: "red accent-2",
            },
            { root: true }
          );
          reject();
        });
    });
  },
  [actionTypes.PASSWORD_RESET]({ dispatch }, email) {
    const url = `${AUTH_REF}/password.reset`;
    return ApiService.patch(url, email).then(({ data }) => {
      dispatch(
        `ui/${ADD_NOTIFICATION}`,
        {
          text: "messages.checkEmailInbox",
          color: "success",
        },
        { root: true }
      );
      return data;
    });
  },
  [actionTypes.RESET_PASSWORD]({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      // for testing only
      ApiService.post(`${AUTH_REF}/reset.password`, params)
        .then(({ data }) => {
          const {
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
            acr,
          } = data;
          const user = {
            email,
            firstName,
            lastName,
            organizationId,
            organizationAcronym,
            roles,
            hubs,
          };
          commit(actionTypes.SET_AUTH, { user, check: true });
          dispatch(`roles/${SET_ROLES}`, acr, { root: true });
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.passwordUpdated",
              color: "success",
              timeout: 10000,
            },
            { root: true }
          );
          resolve(user);
        })
        .catch(() => {
          dispatch(
            `ui/${ADD_NOTIFICATION}`,
            {
              text: "messages.passwordUpdatedFailed",
              color: "grey",
              timeout: 10000,
            },
            { root: true }
          );
          reject();
        });
    });
  },
  // [actionTypes.CHANGE_PASSWORD]({ dispatch }, payload) {
  //   const { id, password } = payload;
  //   return ApiService.patch(USERS_REF, { id, password }).then(({ data }) => {
  //     dispatch(
  //       `ui/${ADD_NOTIFICATION}`,
  //       {
  //         text: "messages.updateCompleted",
  //         color: "success",
  //       },
  //       { root: true }
  //     );
  //     return data;
  //   });
  // },
};

const mutations = {
  [actionTypes.SET_AUTH_ERROR](state, error) {
    state.errors = error;
  },
  [actionTypes.SET_AUTH](state, params) {
    const { user, check } = params;
    state.isAuthenticated = true;
    state.errors = {};
    if (!check) {
      state.user = user;
      if (state.user.token) {
        const {
          token,
          username,
          id,
          organizationId,
          organizationAcronym,
        } = state.user;
        AuthService.saveAuthData(
          token,
          username,
          id,
          organizationId,
          organizationAcronym
        );
      }
    } else {
      state.user = {
        ...state.user,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        organizationId: user.organizationId,
        organizationAcronym: user.organizationAcronym,
        roles: user.roles,
        hubs: user.hubs,
      };
    }
  },
  [actionTypes.PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
    AuthService.deleteAuthData();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
